import _ from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

function updateBooking(state, action) {
  const { data } = action;
  const booking = _.find(state, x => x._id === data.id);

  if (booking) {
    booking.vehicleInfo = data.idCar;
  }
}

const bookings = (state = initialState.bookings, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_BOOKING_COMPLETE:
      if (action.booking) {
        const { booking } = action.booking;
        return booking;
      }
      return state;

    case AppFlowActions.UPDATE_BOOKING_COMPLETE:
    {
      return updateBooking(state, action);
    }
    default:
      return state;
  }
};

export default bookings;
