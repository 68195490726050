import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron, Col, Row,
  Label, Button,
  Input, FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
// import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
// import moment from 'moment';
import Select from 'react-select';
import { isEmpty, startCase } from 'lodash';
import classnames from 'classnames';
import toastr from 'toastr';

import { checkNull, date2UtcSecondEpoch } from '../../utils';
import InputDatePicker from '../InputDatePicker';

class CreateBookingUserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidField: '',
      selectCheckinDate: new Date(),
    };

    this.initData();
  }

  initData = () => {
    this.dataUser = {
      customerName: '',
      phoneNumber: '',
      checkInDate: new Date(),
      note: '',
      lockId: '',
      serialMachine: '',
      repairCosts: null,
    };
  }

  onChangeUserInfo = (e) => {
    const { value, name } = e.target;
    const { invalidField } = this.state;

    if (invalidField !== '') {
      this.setState({ invalidField: '' });
    }

    this.dataUser[name] = value;
  }

  onBookClick = (e) => {
    e.preventDefault();

    const dataCheckNull = [
      { field: 'customerName', value: this.dataUser.customerName },
      { field: 'serialMachine', value: this.dataUser.serialMachine },
      { field: 'regPlate', value: this.dataUser.lockId },
      { field: 'phoneNumber', value: this.dataUser.phoneNumber },
      { field: 'repairCosts', value: this.dataUser.repairCosts },
      { field: 'note', value: this.dataUser.note },
    ];

    const invalidValue = checkNull(dataCheckNull);

    if (invalidValue) {
      this.setState({ invalidField: invalidValue });

      return;
    }

    if (this.dataUser.repairCosts < 0) {
      this.setState({ invalidField: 'repairCosts' });
      toastr.error('Cost invalid', 'Error');
      return;
    }

    this.dataUser.checkInDate = date2UtcSecondEpoch(this.dataUser.checkInDate);
    this.dataUser.phoneNumber = `+${this.dataUser.phoneNumber}`;
    this.dataUser.repairCosts = +this.dataUser.repairCosts;
    this.props.handleFinishBooking(this.dataUser);
  }

  onChangeReturnDate = (date) => {
    this.dataUser.checkInDate = date;
    this.setState({ selectCheckinDate: date });
  }

  formatLocks = () => {
    const { locks } = this.props;
    const { serialMachine } = this.dataUser;

    const currentLocks = locks[serialMachine];

    if (isEmpty(locks) || isEmpty(serialMachine) || isEmpty(currentLocks)) return [];

    return currentLocks.map(lock => ({
      label: lock.regPlate,
      value: lock.id,
      isDisabled: lock.status !== 'available',
      status: startCase(lock.status),
    }));
  }

  handleSelectRegPlate = (data) => {
    this.setState({ invalidField: '' });

    if (data.value) {
      this.dataUser.lockId = data.value;
      return;
    }

    if (!isEmpty(data)) {
      this.dataUser.lockId = data;
    }
  }

  getMachines = () => {
    const { machines } = this.props;

    if (isEmpty(machines)) return [];

    return Object.keys(machines).map(key => ({
      label: machines[key].name,
      value: machines[key].serialMachine,
      customAbbreviation: machines[key].status,
    }));
  }

  handleSelectMachine = ({ value }) => {
    const { onGetLockAvailable } = this.props;
    this.setState({ invalidField: '' });

    onGetLockAvailable(value);
    this.dataUser.serialMachine = value;
    this.dataUser.lockId = null;
  }

  handleSelectLock = ({ value }) => {
    this.setState({ invalidField: '' });

    this.dataUser.lockId = value;
  }

  getInvalidStyleSelect = (field, checkField) => {
    if (field === checkField) {
      return {
        control: provided => ({
          ...provided,
          border: '1px solid #ff5454 !important',
          boxShadow: 'none !important',
        }),
      };
    }

    return {};
  }

  getRegPlateValue = () => {
    const { locks } = this.props;
    const { lockId, serialMachine } = this.dataUser;

    if (!lockId) {
      return {
        value: '',
        label: 'Select lock',
      };
    }

    const currentLocks = locks[serialMachine] || [];
    const lock = currentLocks.find(({ id }) => lockId === id) || {};

    return {
      value: lockId || '',
      label: lock.regPlate || 'Select lock',
    };
  }

  formatLockLabel = ({ label, status, isDisabled }) => (
    <div className={classnames('lock', { 'lock-disabled': isDisabled })}>
      <span className="lock-text">{label}</span>
      <span>{status}</span>
    </div>
  );

  render = () => {
    const { invalidField } = this.state;

    const {
      customerName,
      note,
      serialMachine,
      repairCosts,
    } = this.dataUser;

    return (
      <Jumbotron className="create-booking__jumbotron --child">
        <Col md={{ offset: 2, size: 8 }}>
          <form
            className="create-booking__form mt-4 form-horizontal"
            onSubmit={this.onBookClick}
          >
            <Row>
              <Col xs="12">
                <h2 className="text-center mb-4">
                  <strong>
                    New booking information
                  </strong>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                      Customer name
                  </Label>
                  <Input
                    className={invalidField === 'customerName' ? 'is-invalid' : ''}
                    type="text"
                    name="customerName"
                    placeholder="Customer Name"
                    defaultValue={customerName}
                    onChange={this.onChangeUserInfo}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                      Machine
                  </Label>
                  <Select
                    name="serialMachine"
                    options={this.getMachines()}
                    placeholder="Machine"
                    onChange={this.handleSelectMachine}
                    menuPlacement="bottom"
                    styles={this.getInvalidStyleSelect(invalidField, 'serialMachine')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                      Lock
                  </Label>
                  <Select
                    name="regPlate"
                    options={this.formatLocks()}
                    placeholder="Select reg plate"
                    onChange={this.handleSelectLock}
                    menuPlacement="bottom"
                    styles={this.getInvalidStyleSelect(invalidField, 'regPlate')}
                    isDisabled={!serialMachine}
                    value={this.getRegPlateValue()}
                    formatOptionLabel={this.formatLockLabel}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: '10', offset: '1' }} xs="12">
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                      Phone number
                  </Label>
                  <Input
                    className={invalidField === 'phoneNumber' ? 'is-invalid form-control' : 'form-control'}
                    type="number"
                    name="phoneNumber"
                    placeholder="(Country Calling Codes)Phone Number"
                    // mask="9"
                    // maskChar={null}
                    // defaultValue={phoneNumber}
                    onChange={this.onChangeUserInfo}
                    // ref={this.myInputMaskRefPhone}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                      Check-in date
                  </Label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.selectCheckinDate}
                    onChange={(date) => { this.onChangeReturnDate(date); }}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                        // maxDate={maxDate}
                    customInput={<InputDatePicker />}
                    // showTimeSelect
                    // timeFormat="hh:mm"
                  />
                </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup row className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                    Cost
                  </Label>
                  <InputGroup style={{ paddingRight: 15 }}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>€</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className={invalidField === 'repairCosts' ? 'is-invalid' : ''}
                      type="number"
                      name="repairCosts"
                      placeholder="Cost"
                      defaultValue={repairCosts}
                      onChange={this.onChangeUserInfo}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: '10', offset: '1' }}>
                <FormGroup className="ml-0">
                  <Label className="text__title text__title--info mb-1 field-require">
                    Note
                  </Label>
                  <Input
                    className={invalidField === 'note' ? 'is-invalid' : ''}
                    style={{ height: '12rem' }}
                    type="textarea"
                    name="note"
                    placeholder="Notes"
                    defaultValue={note}
                    onChange={this.onChangeUserInfo}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5 mb-4 justify-content-center">
              <Button
                className="create-booking__btn create-booking__btn-finish"
                onClick={this.onBookClick}
              >
                Add Booking
              </Button>
            </Row>
          </form>
        </Col>
      </Jumbotron>
    );
  }
}

CreateBookingUserInfo.propTypes = {
  handleFinishBooking: PropTypes.func.isRequired,
  locks: PropTypes.objectOf(PropTypes.array).isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
  onGetLockAvailable: PropTypes.func.isRequired,
};

export default CreateBookingUserInfo;
