import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Row, Jumbotron,
  FormGroup, Label, Input,
  InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import _ from 'lodash';

class BookingInfo extends Component {
  constructor(props) {
    super(props);
    const { dataBookingInfo } = this.props;
    const dataBookingInfoState = _.cloneDeep(dataBookingInfo);
    this.state = {
      dataBookingInfoState,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataBookingInfo } = nextProps;
    const { defaultDataBookingInfoState } = prevState;

    if (dataBookingInfo && dataBookingInfo !== defaultDataBookingInfoState) {
      return {
        defaultDataBookingInfoState: dataBookingInfo,
        dataBookingInfoState: dataBookingInfo,
      };
    }

    return null;
  }

  isDisableNote = () => {
    const { rootStatus } = this.props;
    if (!rootStatus) {
      return true;
    }
    if (rootStatus.toLowerCase() !== 'checkedin') {
      return true;
    }
    return false;
  }

  render = () => {
    const { dataBookingInfo, machine } = this.props;

    return (
      <Row className="booking-detail">
        <Col md="12">
          <Jumbotron>
            <Row className="mb-3">
              <Col md="12">
                <Row style={{ marginLeft: 0 }}>
                  <h3 className="text__header--sub">
                    Booking Information
                  </h3>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div>
                  <span className="text__title text__title--info">
                    Customer:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.customerName}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Phone number:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.phoneNumber}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Status:
                  </span>
                  <span className="text__content info--content__block capitalize-first-letter">
                    {dataBookingInfo.status}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Machine name:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.machineName || machine.name}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Key position:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.keyPosition}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text__title text__title--info">
                    Reg.Plate:
                  </span>
                  <span className="text__content info--content__block">
                    {dataBookingInfo.regPlate}
                  </span>
                </div>
                {dataBookingInfo.status === 'booking' ? (
                  <FormGroup row className="ml-0 mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <Label className="text__title" style={{ fontSize: '0.9rem' }}>
                      Cost:
                    </Label>
                    <Col sm={8} className="ml-1">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>€</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          name="repairCosts"
                          id="repairCosts"
                          onChange={this.props.onChangedBookingInfo}
                          value={dataBookingInfo.repairCosts}
                          min="0"
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                ) : (
                  <div className="mt-2">
                    <span className="text__title text__title--info">
                      Cost:
                    </span>
                    <span className="text__content info--content__block">
                      {`€ ${dataBookingInfo.repairCosts || '0'}`}
                    </span>
                  </div>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="text__title text__title--info" for="exampleText">Note</Label>
                  <Input
                    style={{ height: '7rem' }}
                    type="textarea"
                    name="note"
                    id="exampleText"
                    onChange={this.props.onChangedBookingInfo}
                    value={dataBookingInfo.note ? dataBookingInfo.note : ''}
                    disabled={this.isDisableNote()}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Jumbotron>
        </Col>
      </Row>
    );
  }
}

BookingInfo.defaultProps = {
  machine: {},
};

BookingInfo.propTypes = {
  dataBookingInfo: PropTypes.shape().isRequired,
  onChangedBookingInfo: PropTypes.func.isRequired,
  rootStatus: PropTypes.string.isRequired,
  machine: PropTypes.shape(),
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(BookingInfo);
