let width = window.innerWidth;

window.addEventListener('resize', () => {
  width = window.innerWidth;
  if (width <= 992) {
    document.body.classList.remove('brand-minimized', 'sidebar-minimized');
  } else {
    document.body.classList.remove('sidebar-mobile-show');
  }
});
