import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

class InputDatePicker extends Component {
    render = () => {
      const { onChange, value, onClick } = this.props;

      return (
        <Input
          style={{ textAlign: 'left' }}
          onChange={onChange}
          value={value}
          onClick={onClick}
          readOnly
        />
      );
    }
}

export default InputDatePicker;

InputDatePicker.defaultProps = {
  onChange: () => {},
  onClick: () => {},
  value: '',
};

InputDatePicker.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
