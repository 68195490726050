import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import CreateLockInfo from '../../components/CreateLockInfo';
import loadingPage from '../../Redux/Actions/loading';
import getAllRequest from '../../Redux/Actions/getall';
import {
  getAllLocksOfMachineAction,
  getPositionUnavailableAction,
  createLockAction,
} from '../../Redux/Actions/locks';
import arrowLeftCircle from '../../image/arrow-left-circle.svg';

function CreateLock({
  locks,
  machines,
  onGetPositionUnavailable,
  onCreateLock,
  history,
}) {
  return (
    <div className="create-booking">
      <main className="main">
        <Container fluid>
          <Row className="booking-name">
            <Link to="/admin/locks">
              <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
            </Link>
            <div className="create-booking__header">
              <h2 className="text__header">
                  NEW LOCK
              </h2>
            </div>
          </Row>
          <CreateLockInfo
            locks={locks}
            machines={machines}
            onGetPositionUnavailable={onGetPositionUnavailable}
            onCreateLock={onCreateLock}
            history={history}
          />
        </Container>
      </main>
    </div>
  );
}

CreateLock.propTypes = {
  locks: PropTypes.objectOf(PropTypes.object).isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
  onGetPositionUnavailable: PropTypes.func.isRequired,
  onCreateLock: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ locks, machines }) => ({
  locks,
  machines,
});

const mapDispatchToProps = {
  loadingPage,
  getAllRequest,
  onGetAllLocks: getAllLocksOfMachineAction,
  onGetPositionUnavailable: getPositionUnavailableAction,
  onCreateLock: createLockAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLock);
