export default {
  hosts: {
    domain: 'jitsi-meet.example.com',
    muc: 'conference.jitsi-meet.example.com',
  },
  bosh: '//jitsi-meet.example.com/http-bind',
  clientNode: 'http://jitsi.org/jitsimeet',
  testing: {
    enableFirefoxSimulcast: false,
    p2pTestMode: false,
  },
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  desktopSharingChromeExtId: null,
  desktopSharingChromeSources: ['screen', 'window', 'tab'],
  desktopSharingChromeMinExtVersion: '0.1',
  channelLastN: -1,
  enableWelcomePage: true,
  enableUserRolesBasedOnToken: false,
  p2p: {
    enabled: true,
    stunServers: [
      { urls: 'stun:meet-jit-si-turnrelay.jitsi.net:443' },
    ],
    preferH264: true,
  },
  analytics: {
  },
  deploymentInfo: {
  },
  makeJsonParserHappy: 'even if last key had a trailing comma',
};
