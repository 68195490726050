import React, { Component } from 'react';

import './styles/style.scss';

import './utils/minimized';

// Import React Boostrap Table
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

// Import Font Awesome Icons Set
import '../node_modules/font-awesome/css/font-awesome.min.css';

import Main from './views/Main';

class App extends Component {
  render() {
    return (
      <div>
        <Main />
      </div>
    );
  }
}

export default App;
