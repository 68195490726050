import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
} from 'reactstrap';


const DeleteModal = ({
  isOpen,
  onDelete,
  onClose,
  title,
  submitText,
  cancelText,
  description,
}) => (
  <Modal isOpen={isOpen} toggle={onClose}>
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody>
      <Media heading>
        {description}
      </Media>
    </ModalBody>
    <ModalFooter>
      <Button className="btn-delete" onClick={onDelete}>
        {submitText}
      </Button>
      <Button className="btn-edit" onClick={onClose}>
        {cancelText}
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteModal.defaultProps = {
  submitText: 'Submit',
  cancelText: 'Cancel',
  description: 'description',
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  description: PropTypes.string,
};

export default DeleteModal;
