const AppFlowActions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',

  LOADING_COMPLETE: 'LOADING_COMPLETE',
  RELOAD_PAGE_REQUEST: 'RELOAD_PAGE_REQUEST',

  GET_ALL_OCCUPPIED_LOCK_REQUEST: 'GET_ALL_OCCUPPIED_LOCK_REQUEST',
  GET_ALL_OCCUPPIED_LOCK_COMPLETE: 'GET_ALL_OCCUPPIED_LOCK_COMPLETE',

  GET_ALL_MACHINE_REQUEST: 'GET_ALL_MACHINE_REQUEST',
  GET_ALL_MACHINE_COMPLETE: 'GET_ALL_MACHINE_COMPLETE',

  GET_A_MACHINE_REQUEST: 'GET_A_MACHINE_REQUEST',
  GET_A_MACHINE_COMPLETE: 'GET_A_MACHINE_COMPLETE',

  GET_ALL_DATA_REQUEST: 'GET_ALL_DATA_REQUEST',
  GET_ALL_DATA_COMPLETE: 'GET_ALL_DATA_COMPLETE',

  GET_ALL_BOOKING_REQUEST: 'GET_ALL_BOOKING_REQUEST',
  GET_ALL_BOOKING_COMPLETE: 'GET_ALL_BOOKING_COMPLETE',

  GET_A_BOOKING_REQUEST: 'GET_A_BOOKING_REQUEST',
  GET_A_BOOKING_COMPLETE: 'GET_A_BOOKING_COMPLETE',

  UPDATE_BOOKING_REQUEST: 'UPDATE_BOOKING_REQUEST',
  UPDATE_BOOKING_COMPLETE: 'UPDATE_BOOKING_COMPLETE',

  SET_SEARCH_REQUEST: 'SET_SEARCH_REQUEST',
  SET_SEARCH_COMPLETE: 'SET_SEARCH_COMPLETE',

  START_CONFERENCE: 'START_CONFERENCE',
  TRACK_ADDED: 'TRACK_ADDED',
  TRACK_REMOVED: 'TRACK_REMOVED:',
  STOP_CONFERENCE: 'STOP_CONFERENCE',

  GET_ALL_REG_PLATE_REQUEST: 'GET_ALL_REG_PLATE_REQUEST',
  GET_ALL_REG_PLATE_COMPLETE: 'GET_ALL_REG_PLATE_COMPLETE',

  GET_ALL_LOCKS_OF_MACHINE_REQUEST: 'GET_ALL_LOCKS_OF_MACHINE_REQUEST',
  GET_ALL_LOCKS_OF_MACHINE_COMPLETE: 'GET_ALL_LOCKS_OF_MACHINE_COMPLETE',

  GET_POSTION_UNAVAILABLE_REQUEST: 'GET_POSTION_UNAVAILABLE_REQUEST',
  GET_POSTION_UNAVAILABLE_COMPLETE: 'GET_POSTION_UNAVAILABLE_COMPLETE',

  CREATE_LOCK_REQUEST: 'CREATE_LOCK_REQUEST',
  CREATE_LOCK_COMPLETE: 'CREATE_LOCK_COMPLETE',

  DELETE_LOCK_REQUEST: 'DELETE_LOCK_REQUEST',
  DELETE_LOCK_COMPLETE: 'DELETE_LOCK_COMPLETE',

  GET_LOCK_AVAILABLE_REQUEST: 'GET_LOCK_AVAILABLE_REQUEST',
  GET_LOCK_AVAILABLE_COMPLETE: 'GET_LOCK_AVAILABLE_COMPLETE',

  UPDATE_LOCK_REQUEST: 'UPDATE_LOCK_REQUEST',
  UPDATE_LOCK_COMPLETE: 'UPDATE_LOCK_COMPLETE',

  GET_LOCKS_OCCUPIED_REQUEST: 'GET_LOCKS_OCCUPIED_REQUEST',
  GET_LOCKS_OCCUPIED_COMPLETE: 'GET_LOCKS_OCCUPIED_COMPLETE',
};

export default AppFlowActions;
