
export default {
  login: {},
  loading: {},
  machines: {},
  bookings: [],
  occuppiedLocks: [],
  search: {
    bookingSearchString: '',
    machineSearchString: '',
    vehicleSearchString: '',
    optionSearchString: '',
  },
  tracks: {},
  regPlates: [],
  locks: {
    locks: {},
    lockIds: [],
    positionOccuppied: {},
    locksAvailable: {},
    locksOccupied: {},
  },
};
