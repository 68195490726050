const HOST = 'https://ssy-group.uc.r.appspot.com';
// const HOST = 'http://localhost:8080';

export const LOGIN_URL = `${HOST}/users/signin`;

export const MACHINE_URL = `${HOST}/machines`;
export const BOOKING_URL = `${HOST}/bookings`;
export const BOOKING_QR_URL = `${HOST}/bookings/QRCode`;
export const ALL_OCCUPPIED_LOCK_URL = `${HOST}/machine/lock/occuppied?`;
export const CHECKIN = `${HOST}/bookings/checkin`;
export const REG_PLATE = `${HOST}/regplates`;
export const LOCKS = `${HOST}/locks`;
export const LOCK = `${HOST}/lock`;
