import React, { Component } from 'react';

class Footer extends Component {
  render = () => (
    <footer className="app-footer">
      <span>
        <a href="http://finnrent.fi/" target="_blank" rel="noopener noreferrer">
          Finnrent
        </a>
        {' '}
          {/* &copy; 2010 */}
      </span>
    </footer>
  )
}

export default Footer;
