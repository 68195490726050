import initialState from './initialState';
import AppFlowActions from '../../constants';

const tracks = (state = initialState.tracks, action) => {
  switch (action.type) {
    case AppFlowActions.TRACK_ADDED: {
      const { track } = action;
      state[track.participant] = track;
      return { ...state };
    }
    case AppFlowActions.STOP_CONFERENCE: {
      return {};
    }
    default:
      return state;
  }
};

export default tracks;
