import {
  put, call, take, fork,
} from 'redux-saga/effects';
import toastr from 'toastr';

import AppFlowActions from '../../constants';
import {
  getAllLocksOfMachineService,
  getPositionUnavailableService,
  createLockService,
  deleteLockService,
  getLockAvailableService,
  updateLockService,
  getLocksOccupiedService,
} from '../Helpers/fetch';
import { memorizeLocks } from '../../utils/memorize';

const formatLockStatus = (status) => {
  switch (status) {
    case 'available':
      return 1;
    case 'assigned_to_booking':
      return 2;
    case 'out_of_machine':
      return 3;
    default:
      return 1;
  }
};

function* getAllLocksOfMachineSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      yield take(AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const response = yield call(getAllLocksOfMachineService);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (response.error) {
        return;
      }

      yield put({
        type: AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_COMPLETE,
        payload: memorizeLocks(response),
      });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

function* getPostionUnavailableSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { serialMachine } = yield take(AppFlowActions.GET_POSTION_UNAVAILABLE_REQUEST);

      const response = yield call(getPositionUnavailableService, serialMachine);

      if (response.error) {
        return;
      }

      yield put({
        type: AppFlowActions.GET_POSTION_UNAVAILABLE_COMPLETE,
        payload: {
          serialMachine,
          positionOccuppied: response,
        },
      });
    } catch (error) {
      toastr.error(error.message, 'Error');
    }
  }
}

function* createLockSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { lock, history } = yield take(AppFlowActions.CREATE_LOCK_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const response = yield call(createLockService, lock);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (response.error) {
        toastr.error(response.error.message, 'Error');
        return;
      }

      toastr.success('Create lock successful');
      history.push('/admin/locks');
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

function* deleteLockSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { idLock } = yield take(AppFlowActions.DELETE_LOCK_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const response = yield call(deleteLockService, idLock);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (response.error) {
        toastr.error(response.error.message, 'Error');
        return;
      }

      toastr.success('Delete lock successful');
      yield put({ type: AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_REQUEST });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

function* getLockAvailableSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { serialMachine } = yield take(AppFlowActions.GET_LOCK_AVAILABLE_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const response = yield call(getLockAvailableService, serialMachine);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (response.error) {
        toastr.error(response.error.message, 'Error');
        return;
      }

      const locksAvailable = response.sort((a, b) => formatLockStatus(a.status) - formatLockStatus(b.status));

      yield put({
        type: AppFlowActions.GET_LOCK_AVAILABLE_COMPLETE,
        payload: {
          serialMachine,
          locksAvailable,
        },
      });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

function* updateLockSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { lockId, lock } = yield take(AppFlowActions.UPDATE_LOCK_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const response = yield call(updateLockService, lockId, lock);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (response.error) {
        toastr.error(response.error.message, 'Error');
        return;
      }

      toastr.success('Update lock successful');
      yield put({ type: AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_REQUEST });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

function* getLocksOccupiedSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      const { serialMachine } = yield take(AppFlowActions.GET_LOCKS_OCCUPIED_REQUEST);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const locksOccupied = yield call(getLocksOccupiedService, serialMachine);

      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (locksOccupied.error) {
        toastr.error(locksOccupied.error.message, 'Error');
        return;
      }

      yield put({
        type: AppFlowActions.GET_LOCKS_OCCUPIED_COMPLETE,
        payload: {
          serialMachine,
          locksOccupied,
        },
      });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

export default function* locksFlow() {
  yield fork(getAllLocksOfMachineSaga);
  yield fork(getPostionUnavailableSaga);
  yield fork(createLockSaga);
  yield fork(deleteLockSaga);
  yield fork(getLockAvailableSaga);
  yield fork(updateLockSaga);
  yield fork(getLocksOccupiedSaga);
}
