import {
  put, call, take, fork,
} from 'redux-saga/effects';
import AppFlowActions from '../../constants';
import { fetchAll } from '../Helpers/fetch';

export function* getAllRequest() {
  const INFINITE = true;
  while (INFINITE) {
    yield take(AppFlowActions.GET_ALL_DATA_REQUEST);
    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });

    const data = yield call(fetchAll);
    console.log('get all ', data);
    if (data) {
      yield put({ type: AppFlowActions.GET_ALL_DATA_COMPLETE, data });
    }

    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
  }
}

export default function* getAllFlow() {
  yield fork(getAllRequest);
}
