import { isEmpty } from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

export default function(state = initialState.regPlates, action) {
  switch (action.type) {
    case AppFlowActions.GET_ALL_REG_PLATE_COMPLETE: {
      const { regPlates } = action;
      if (isEmpty(regPlates)) return state;

      return regPlates;
    }
    default:
      return state;
  }
};
