import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Full from './Full';
import Login from './Login';
import NotFound from './NotFound';
import TermsConditions from './TermsConditions';
import PrivateRoute from '../components/PrivateRoute';
import Loading from '../components/Loading';
import auth from '../Redux/Helpers/auth';

class Main extends Component {
  render = () => {
    const { authenticated } = this.props;

    return (
      <main>
        <Loading />
        <Router>
          <Switch>
            <Route path="/login" name="login" component={Login} />
            {/* <Route path="/termsconditions" name="terms-conditions" component={TermsConditions} /> */}
            <PrivateRoute path="/" name="full" component={Full} authenticated={authenticated} />
            <Route path="*" name="notFound" component={NotFound} />
          </Switch>
        </Router>
      </main>
    );
  }
}

Main.defaultProps = {
  authenticated: false,
};

Main.propTypes = {
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: !!auth.token(),
  };
}

export default connect(mapStateToProps)(Main);
