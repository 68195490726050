export const RemoteControl = {
  PROJECT_ID: 'ssy-group',
  REQUEST_FROM_WEB_TOPIC: 'ssy-group/requestFromWeb',
  RESPONSE_FROM_MACHINE_TOPIC: 'ssy-group/responseFromMachine',
  SOCKET_URL: 'https://socket.ssy-group.serviceautomatics.com',
  LOCAL_SOCKET_URL: 'http://localhost:65080',
};

export const RCMessageType = {
  REQ: 101,
  RSP: 102,
};

export const RCDoorPosition = {
  UPPER: 301,
  LOWER: 302,
};

export const RCRequestCode = {
  TOGGLE_DOOR: 131,
  RELEASE_KEY: 132,
  OPEN_VIDEO: 133,
  CLOSE_VIDEO: 134,
};

export const RCResponseCode = {
  OK: 200,
  OPERATION_NOT_SUPPORTED: 230,
  INVALID_PARAMETER: 231,
  INVALID_OPERATION: 232,
  PROTOCOL_VERSION_NOT_SUPPORTED: 233,
  FAIL: 234,
  TIMEOUT: 250,
  BUSY: 256,
  STOP_STREAM: 135,
};
