import {
  put, call, take, fork,
} from 'redux-saga/effects';

import AppFlowActions from '../../constants';
import { fetchAllBooking, fetchABooking, fetchUpdateBooking } from '../Helpers/fetch';
import { GET_ALL_BOOKING_URL } from '../../config';

function fetch(bookingId) {
  try {
    return fetchABooking(bookingId);
  } catch (error) {
    return null;
  }
}

function fetchAll() {
  try {
    return fetchAllBooking();
  } catch (error) {
    return null;
  }
}

function fetchUpdate(data) {
  try {
    return fetchUpdateBooking(data);
  } catch (error) {
    return null;
  }
}

function* allBookingListFlow() {
  const INFINITE = true;

  while (INFINITE) {
    yield take(AppFlowActions.GET_ALL_BOOKING_REQUEST);
    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });

    const booking = {
      booking: yield call(fetchAll),
    };
    console.log('all booking ', booking);
    if (booking) {
      yield put({ type: AppFlowActions.GET_ALL_BOOKING_COMPLETE, booking });
    }

    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
  }
}

function* aBookingListFlow() {
  const INFINITE = true;

  while (INFINITE) {
    const result = yield take(AppFlowActions.GET_A_BOOKING_REQUEST);
    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });

    const booking = {
      booking: yield call(fetch, result.bookingId),
    };

    console.log('a booking ', booking);

    if (booking) {
      yield put({ type: AppFlowActions.GET_A_BOOKING_COMPLETE, booking });
    }

    yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
  }
}

function* updateBookingFlow() {
  const INFINITE = true;

  while (INFINITE) {
    const respond = yield take(AppFlowActions.UPDATE_BOOKING_REQUEST);
    const { data } = respond;

    const booking = {
      booking: yield call(fetchUpdate, data),
    };

    console.log('update booking data ', booking);
    console.log('update booking result ', booking);

    if (booking) {
      yield put({ type: AppFlowActions.UPDATE_BOOKING_COMPLETE, data });
    }
  }
}

export default function* bookingFlow() {
  yield fork(aBookingListFlow);
  yield fork(allBookingListFlow);
  yield fork(updateBookingFlow);
}
