import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

import setSearchRequest from '../../Redux/Actions/search';
import { generateList, addSearchValues } from '../../utils';

library.add(faCircle, faSearch);

class KeyVending extends Component {
  state= { search: '' }

  onChangeSearch = (e) => {
    const { value } = e.target;

    this.setState({
      search: value,
    });

    if (this.searchTimer !== null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.props.setSearchRequest({ bookingSearchString: value });
    }, 500);
  }

  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  statusFormater = (cell) => {
    const greenCircle = (
      <div className="status">
        <FontAwesomeIcon icon="circle" className="status-circle --green" />
        <div className="status--text">
          Online
        </div>
      </div>
    );

    const grayCircle = (
      <div className="status">
        <FontAwesomeIcon icon="circle" className="status-circle --gray" />
        <div className="status--text">
          Offline
        </div>
      </div>
    );

    if (!cell) return grayCircle;

    return cell.toLowerCase() === 'offline' ? grayCircle : greenCircle;
  }

  renderPageList = () => {
    const { data } = this.props;

    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    return listPage;
  }

  renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => this.onToggleDropDown(props.toggleDropDown)}
    />
  )

  render = () => {
    const { data, history } = this.props;
    const { search } = this.state;
    const dataFilter = generateList(data, search);

    if (data) {
      _.forEach(data, (value) => {
        addSearchValues(value, value,
          ['machineId', 'machineName', 'status']);
      });
    }

    return (
      <div className="machines">
        <InputGroup className="form-group form-group-sm react-bs-table-search-form
        input-group input-group-sm input-search__remove-border"
        >
          <Input
            type="text"
            id="search"
            placeholder="Search"
            onChange={this.onChangeSearch}
            defaultValue={search}
          />
          <InputGroupAddon className="input-group-addon input-search__overlap" addonType="prepend">
            <FontAwesomeIcon icon="search" />
          </InputGroupAddon>
        </InputGroup>
        <BootstrapTable
          data={_.orderBy(dataFilter, ['machineId'], ['desc'])}
          options={{
            sizePerPageList: this.renderPageList(),
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            onRowClick(row) {
              history.push(`../admin/machines/${row.serialMachine}`);
            },
          }}
          styleRow={this.styleRow}
          pagination
          striped
          multiColumnSort={3}
        >
          <TableHeaderColumn
            isKey
            dataField="machineId"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="machineName"
            dataAlign="center"
            dataSort
            width="200"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataAlign="center"
            dataFormat={this.statusFormater}
            dataSort
            // width="200"
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="lastConnectStringFormat"
            dataAlign="center"
            // width="300"
          >
            Last Connect
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

KeyVending.defaultProps = {
  data: [],
};

KeyVending.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setSearchRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  setSearchRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(KeyVending));
