import memoize from 'memoize-one';
import _ from 'lodash';
import toastr from 'toastr';
import moment from 'moment';

export const addSearchValues = (object, searchObject, searchKeys) => {
  const objKeys = Object.keys(searchObject);
  object.searchValues = [];

  _.forEach(searchKeys, (key) => {
    if (objKeys.includes(key)) {
      let value = searchObject[key];

      if (value !== undefined && value !== null) {
        value = String(value).toLowerCase();

        if (value !== '') {
          object.searchValues.push(value);
        }
      }
    }
  });
};

export const filterData = memoize((data, searchString) => {
  const searchStrings = _.compact(searchString.toLowerCase().split(' '));

  if (!searchStrings.length || !data.length) {
    return data;
  }

  const filtered = _.filter(data, (row) => {
    const found = false;

    if (row.searchValues) {
      for (let i = 0; i < row.searchValues.length; i += 1) {
        const searchValue = row.searchValues[i];

        for (let j = 0; j < searchStrings.length; j += 1) {
          const searchElement = searchStrings[j];

          if (searchElement === 'valid' && searchValue === 'invalid') {
            return false;
          }

          if (searchValue.includes(searchElement)) {
            return true;
          }
        }
      }
    }

    return found;
  });

  return filtered;
});

export const generateList = memoize((data, search) => {
  const dataFilter = search ? filterData(data, search) : data;

  for (let i = 0; i < dataFilter.length; i += 1) {
    dataFilter[i].index = i;
  }

  return dataFilter;
});

export const checkDoB = (DOB) => {
  const validDobCharacters = /^([0-9]{2})[-/.]([0-9]{2})[-/.]([0-9]{4})$/.test(DOB);

  if (!validDobCharacters || !moment(DOB, 'DD-MM-YYYY', true).isValid()) {
    toastr.error('Invalid date of birth', 'Error');
    return false;
  }

  const DOBReplace = moment(DOB.replace(/-/g, '/'), 'DD-MM-YYYY').format('MM-DD-YYYY');

  if (new Date(DOBReplace).getTime() >= new Date(moment().startOf('d')).getTime()) {
    toastr.error('Invalid date of birth', 'Error');
    return false;
  }

  return true;
};

export const checkDate = (date) => {
  const validDateCharacters = /^([0-9]{2})[-/.]([0-9]{2})[-/.]([0-9]{4})$/.test(date);

  if (!validDateCharacters || !moment(date, 'DD-MM-YYYY', true).isValid()) {
    toastr.error('Invalid date', 'Error');
    return false;
  }

  return true;
};

export const checkFloat = (number) => {
  const validFloatCharacters = /^\d*(\.\d{0,1})?\d$/.test(number);

  if (!validFloatCharacters) {
    return false;
  }

  return true;
};

export const checkNull = (dataCheckNull) => {
  let dataReturn = null;

  _.forEach(dataCheckNull, (data) => {
    if (!data.value) {
      toastr.error('Please fill out the missing fields', 'Error');
      dataReturn = data.field;
      return false;
    }

    if (data.field === 'voucherNo' && data.value <= 0) {
      toastr.error('Please enter a valid voucher number', 'Error');
      dataReturn = data.field;
      return false;
    }

    if ((data.field === 'DOB' || data.field === 'dobPPInput')
      && !checkDoB(data.value)) {
      dataReturn = data.field;
      return false;
    }

    if ((data.field === 'expiryDatePP' || data.field === 'issueDateDL' || data.field === 'expiryDateDL')
      && !checkDate(data.value)) {
      dataReturn = data.field;
      return false;
    }

    return true;
  });

  return dataReturn;
};

export const formatDisplayDate = unixTime => (unixTime ? moment.unix(unixTime).format('DD-MM-YYYY') : '');

export const date2SecondEpoch = date => Math.floor(moment(date).utc().valueOf() / 1000);
export const date2UtcSecondEpoch = date => Math.floor(moment(date).utcOffset(0).startOf('day').valueOf() / 1000);
export const date2MiliSecondEpoch = date => Math.floor(moment(date).valueOf());
export const second2MiliSecondEpoch = epoch => epoch * 1000;
export const miliSecond2SecondEpoch = epoch => Math.floor(epoch / 1000);

export const formatLastConnectDate = (date) => {
  const currentDate = moment();
  const pastDate = moment.unix(date);
  const days = currentDate.diff(pastDate, 'days');
  const hours = currentDate.subtract(days, 'days').diff(pastDate, 'hours');
  const minutes = currentDate.subtract(hours, 'hours').diff(pastDate, 'minutes');
  const dayLabel = days === 1 ? 'day' : 'days';
  const hourLabel = hours === 1 ? 'hour' : 'hours';
  const minuteLabel = minutes === 1 || minutes === 0 ? 'minute' : 'minutes';
  const dayString = days === 0 ? '' : `${days} ${dayLabel},`;
  const hourString = hours === 0 ? '' : `${hours} ${hourLabel},`;
  const minuteString = `${minutes} ${minuteLabel}`;

  return `${dayString} ${hourString} ${minuteString} ago`;
};

export const getParamSearch = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};
