import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  NavbarBrand, NavbarToggler,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import logoutIcon from '../../image/logout.svg';

import { logoutRequest } from '../../Redux/Actions/login';
import emitter from '../../utils/emitter';
import AppFlowActions from '../../constants';

library.add(faBars, faUserCircle);

class Header extends Component {
  state = { dropdownOpenAvatar: false }

  constructor(props) {
    super(props);
    this.state = { dropdownOpenAvatar: false };
    this.listener = null;
  }

  componentDidMount = () => {
    this.listener = emitter.addListener(AppFlowActions.LOGOUT_REQUEST, (data) => {
      if (data) {
        this.props.logoutRequest();
      }
    });
  }

  componentWillUnmount = () => {
    if (this.listener) {
      this.listener.remove();
    }
  }

  mobileSidebarToggle = (e) => {
    e.preventDefault();

    document.body.classList.toggle('sidebar-mobile-show');
  }

  toggleAvatar = () => {
    this.setState(prevState => ({
      dropdownOpenAvatar: !prevState.dropdownOpenAvatar,
    }));
  }

  sidebarToggle = (e) => {
    e.preventDefault();

    document.body.classList.toggle('sidebar-hidden');
  }

  brandMinimize = (e) => {
    e.preventDefault();

    document.body.classList.toggle('brand-minimized');
  }

  sidebarMinimize = (e) => {
    e.preventDefault();

    document.body.classList.toggle('sidebar-minimized');
  }

  onClickLogout = () => {
    this.props.logoutRequest();
  }

  render = () => {
    const { dropdownOpenAvatar } = this.state;
    const { userName } = this.props;

    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none navbar-toggler-mobile" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <NavbarBrand />
        <NavbarToggler
          className="d-md-down-none navbar-toggler-sidebar"
          onClick={(event) => { this.brandMinimize(event); this.sidebarMinimize(event); }}
        >
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <ul>
          <li className="navbar__welcome">
            <p>
              Welcome,
              {' '}
              <strong>
                {userName}
              </strong>
            </p>
          </li>
          <Dropdown
            className="dropdown-avatar"
            nav
            isOpen={dropdownOpenAvatar}
            toggle={this.toggleAvatar}
          >
            <DropdownToggle nav>
              <FontAwesomeIcon icon="user-circle" className="avatar" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="text-center dropdown-item--hide">
                <div>
                  Welcome,
                  {' '}
                  <strong>
                    {userName}
                  </strong>
                </div>
              </DropdownItem>
              <DropdownItem className="text-center" onClick={this.onClickLogout}>
                <div>
                  <img src={logoutIcon} alt="logout" className="icon-logout" />
                  <span>
                    Logout
                  </span>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ul>
      </header>
    );
  }
}

Header.propTypes = {
  userName: PropTypes.string.isRequired,
  logoutRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logoutRequest,
};

export default connect(null, mapDispatchToProps)(Header);
