import _ from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

const occuppiedLocks = (state = initialState.occuppiedLocks, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_OCCUPPIED_LOCK_COMPLETE:
      if (action.occuppiedLock) {
        const { occuppiedLock } = action.occuppiedLock;

        return occuppiedLock;
      }

      return state;
    default:
      return state;
  }
};

export default occuppiedLocks;
