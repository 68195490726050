import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import setSearchRequest from '../../Redux/Actions/search';
import { generateList, addSearchValues } from '../../utils';

library.add(faSearch);

export const renderLock = (col, { lock }) => (
  <Link to="/admin/locks" style={{ color: '#F1C52E' }} onClick={e => e.stopPropagation()}>
    {_.get(lock, 'regPlate', '')}
  </Link>
);

class Bookings extends Component {
  state = { search: '' }

  onChangeSearch = (e) => {
    const { value } = e.target;

    this.setState({ search: value });

    if (this.searchTimer !== null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.props.setSearchRequest({ bookingSearchString: value });
    }, 500);
  }

  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderPageList = () => {
    const { data } = this.props;
    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    const element = {
      text: 'All',
      value: data.length,
    };

    listPage.push(element);

    return listPage;
  }

  renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => this.onToggleDropDown(props.toggleDropDown)}
    />
  )

  render = () => {
    const { search } = this.state;
    const {
      data, history,
      isDataBookings, isDataCheckins, isDataCheckouts,
    } = this.props;

    const dataFilter = generateList(data, search);

    if (data) {
      _.forEach(data, (value) => {
        addSearchValues(value, value,
          ['idBooking', 'idCheckIn', 'customerName', 'bookingQrcode', 'checkInQrcode',
            'typeCar', 'timeBook', 'timeBookCheckIn', 'timeBookCheckOut',
            'timeCheckIn', 'timeReceiveCar', 'timeReturnCar', 'status']);
      });
    }

    return (
      <div className="bookings">
        <InputGroup
          className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm input-search__remove-border"
        >
          <Input
            type="text"
            placeholder="Search"
            onChange={this.onChangeSearch}
            defaultValue={search}
          />
          <InputGroupAddon className="input-group-addon input-search__overlap" addonType="prepend">
            <FontAwesomeIcon icon="search" />
          </InputGroupAddon>
        </InputGroup>
        <BootstrapTable
          data={_.orderBy(dataFilter, ['idBooking', 'idCheckIn'], ['desc', 'desc'])}
          className="cursor-pointer__table"
          options={{
            sizePerPageList: this.renderPageList(),
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            paginationSize: 4,
            onRowClick(row) {
              if (isDataBookings) {
                history.push(`../admin/bookings/new/${row.bookingQrcode}`);
              } else if (isDataCheckins) {
                history.push(`../admin/bookings/checkin/${row.checkInQrcode}`);
              } else {
                history.push(`../admin/bookings/checkout/${row.checkInQrcode}`);
              }
            },
          }}
          pagination
          striped
          hover
          multiColumnSort={3}
        >
          <TableHeaderColumn
            isKey
            dataField={isDataBookings ? 'idBooking' : 'idCheckIn'}
            dataAlign="center"
            dataSort
            width="100"
          >
            ID
          </TableHeaderColumn>
          {
            isDataBookings ? (
              <TableHeaderColumn
                dataField="bookingQrcode"
                dataAlign="center"
                width="150"
              >
                Booking QR Code
              </TableHeaderColumn>
            ) : (
              <TableHeaderColumn
                dataField="checkInQrcode"
                dataAlign="center"
                width="150"
              >
                Check-in QR Code
              </TableHeaderColumn>
            )
          }
          <TableHeaderColumn
            dataField="customerName"
            dataAlign="center"
            width="200"
          >
            Customer Name
          </TableHeaderColumn>
          {
            isDataBookings
              ? (
                <TableHeaderColumn
                  dataField="timeBook"
                  dataAlign="center"
                  width="200"
                >
                  Booking Time
                </TableHeaderColumn>
              )
              : (
                <TableHeaderColumn hidden />
              )
          }
          {
            isDataCheckins || isDataCheckouts ? (
              <TableHeaderColumn
                dataField="timeCheckIn"
                dataAlign="center"
                width="200"
              >
                Check-in Time
              </TableHeaderColumn>
            ) : (
              <TableHeaderColumn hidden />
            )
          }
          {
            !isDataBookings ? (
              <TableHeaderColumn
                dataField="timeReceiveCar"
                dataAlign="center"
                width="200"
              >
                Receiver Car Time
              </TableHeaderColumn>
            ) : (
              <TableHeaderColumn hidden />
            )
          }
          {
            isDataCheckouts ? (
              <TableHeaderColumn
                dataField="timeReturnCar"
                dataAlign="center"
                width="200"
              >
                Return Car Time
              </TableHeaderColumn>
            ) : (
              <TableHeaderColumn
                dataField="status"
                dataAlign="center"
                dataSort
                width="150"
              >
                Status
              </TableHeaderColumn>
            )
          }
        </BootstrapTable>
      </div>
    );
  }
}

Bookings.defaultProps = {
  data: [],
  isDataBookings: false,
  isDataCheckins: false,
  isDataCheckouts: false,
};

Bookings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isDataBookings: PropTypes.bool,
  isDataCheckins: PropTypes.bool,
  isDataCheckouts: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setSearchRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setSearchRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(Bookings));
