import { fork } from 'redux-saga/effects';
import loginFlow from './login';
import getAllFlow from './getall';
import bookingFlow from './booking';
import machineFlow from './machine';
import searchFlow from './search';
import regPlateFlow from './regPlates';
import locksFlow from './locks';

export default function* root() {
  yield fork(loginFlow);
  yield fork(getAllFlow);
  yield fork(bookingFlow);
  yield fork(machineFlow);
  yield fork(searchFlow);
  yield fork(regPlateFlow);
  yield fork(locksFlow);
}
