import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faServer, faIdBadge, faHistory, faArchive,
} from '@fortawesome/free-solid-svg-icons';

library.add(faServer, faIdBadge, faHistory, faArchive);

class Sidebar extends React.Component {
  sidebarClick = () => {
    document.body.classList.toggle('sidebar-mobile-show');
  }

  render = () => {
    const { menu } = this.props;

    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            {
              _.map(menu, item => (
                <NavItem key={item.id} className="nav-item">
                  <NavLink to={item.link} onClick={this.sidebarClick} className="nav-link" activeClassName="selected">
                    <FontAwesomeIcon icon={item.icon} />
                    {item.name}
                  </NavLink>
                </NavItem>
              ))
            }
          </ul>
        </nav>
      </div>
    );
  }
}

Sidebar.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidebar;
