import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
// import moment from 'moment';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import MachineInfo from '../../components/MachineDetail/MachineInfo';
import KeyStatus from '../../components/MachineDetail/KeyStatus';
// import CabinetMap from '../../components/MachineDetail/CabinetMap';

import loadingPage from '../../Redux/Actions/loading';
// import getOccuppiedLock from '../../Redux/Actions/occuppiedLock';
// import { getAMachineRequest } from '../../Redux/Actions/machine';
import { fetchAMachine } from '../../Redux/Helpers/fetch';
import { startConference, stopConference } from '../../Redux/Actions/machine';
import { getLocksOccupiedAction } from '../../Redux/Actions/locks';

class MachineDetail extends Component {
  constructor() {
    super();

    this.state = {
      dataMachines: {},
    };
  }

  componentDidMount = () => {
    const { match, onGetLocksOccupied } = this.props;
    const { serialMachine } = match.params;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    this.fetchMachineDetail().then((response) => {
      try {
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        this.setState({
          dataMachines: response,
        });
        return response;
      } catch (error) {
        console.log('fetchDataDetail', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });

    onGetLocksOccupied(serialMachine);
  }

  fetchMachineDetail = async () => {
    const { match } = this.props;
    const { serialMachine } = match.params;

    const result = await fetchAMachine(serialMachine);
    return result;
  }

  componentDidUpdate = (prevProps) => {
    const { machines, match } = this.props;
    const { serialMachine } = match.params;

    if (!isEqual(machines, prevProps.machines)) {
      const dataTable = this.loadData(machines, serialMachine);

      this.setState({ dataMachines: dataTable.dataMachines });
    }
  }

  onClickEditMachine = serialMachine =>
    this.props.history.push(`/admin/updatemachine/${serialMachine}`);

  loadData = (machines, serialMachine) => {
    if (isEmpty(machines)) {
      this.props.loadingPage(true);
    }

    let dataMachines;

    const machine = machines[serialMachine];

    if (!isEmpty(machine)) {
      // const currentDate = moment();
      // const pastDate = moment(machine.lastSeen);
      // const days = currentDate.diff(pastDate, 'days');
      // const hours = currentDate.subtract(days, 'days').diff(pastDate, 'hours');
      // const minutes = currentDate.subtract(hours, 'hours').diff(pastDate, 'minutes');
      // const dayLabel = days === 1 ? 'day' : 'days';
      // const hourLabel = hours === 1 ? 'hour' : 'hours';
      // const minuteLabel = minutes === 1 ? 'minute' : 'minutes';
      // const dayString = days === 0 ? '' : `${days} ${dayLabel},`;
      // const hourString = hours === 0 ? '' : `${hours} ${hourLabel},`;
      // const minuteString = `${minutes} ${minuteLabel}`;

      dataMachines = machine;
    }

    this.props.loadingPage(false);

    return ({ dataMachines });
  };

  render = () => {
    const {
      onStartConference,
      tracks,
      onStopConference,
      locksOccupied,
      match: {
        params: { serialMachine },
      },
    } = this.props;
    const { dataMachines } = this.state;

    console.log('dataMachines ', dataMachines);

    return (
      <div className="portal">
        <div className="main">
          <Container fluid>
            <Row className="machine-name">
              <Link to={{
                pathname: '/admin/machines',
              }}
              >
                <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
              </Link>
              <h2 className="mb-3 text__header">
                MACHINE
              </h2>
              <h3>
                {
                  dataMachines
                    ? dataMachines.name
                    : ''
                }
              </h3>
            </Row>
            <Row>
              <Col lg="4" md="12" xs="12">
                <MachineInfo
                  dataMachines={dataMachines}
                  onClickEditMachine={this.onClickEditMachine}
                  onStartConference={onStartConference}
                  tracks={tracks}
                  onStopConference={onStopConference}
                />
              </Col>
              <Col lg="8" md="12" xs="12" className="machine-right">
                <KeyStatus dataMachines={dataMachines} locksOccupied={locksOccupied[serialMachine]} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

MachineDetail.defaultProps = {
};

MachineDetail.propTypes = {
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
  loadingPage: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  tracks: PropTypes.objectOf(PropTypes.object).isRequired,
  onStartConference: PropTypes.func.isRequired,
  onStopConference: PropTypes.func.isRequired,
  onGetLocksOccupied: PropTypes.func.isRequired,
  locksOccupied: PropTypes.objectOf(PropTypes.array).isRequired,
};

function mapStateToProps(state) {
  return {
    machines: state.machines,
    tracks: state.tracks,
    locksOccupied: state.locks.locksOccupied,
  };
}

const mapDispatchToProps = {
  loadingPage,
  onStartConference: startConference,
  onStopConference: stopConference,
  onGetLocksOccupied: getLocksOccupiedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetail);
