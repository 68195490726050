import {
  put, call, take, fork,
} from 'redux-saga/effects';
import toastr from 'toastr';

import AppFlowActions from '../../constants';
import { getAllRegPLateService } from '../Helpers/fetch';

function* getAllRegPLateSaga() {
  const INFINITE = true;
  while (INFINITE) {
    try {
      yield take(AppFlowActions.GET_ALL_REG_PLATE_REQUEST);
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });
      const regPlates = yield call(getAllRegPLateService);
  
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
      if (regPlates.error) {
        return;
      };

      yield put({ type: AppFlowActions.GET_ALL_REG_PLATE_COMPLETE, regPlates });
    } catch (error) {
      toastr.error(error.message, 'Error');
      yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}

export default function* regPlateFlow() {
  yield fork(getAllRegPLateSaga);
}
