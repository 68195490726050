import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import toastr from 'toastr';
import {
  Container, Col, Row,
  Button,
} from 'reactstrap';
import loadingPage from '../../Redux/Actions/loading';
import { getAllBookingRequest } from '../../Redux/Actions/booking';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import BookingInfo from '../../components/BookingDetail/BookingInfo';
import ModalSlider from '../../components/ModalSlider';
import { fetchABooking, fetchUpdateBooking, fetchCheckin } from '../../Redux/Helpers/fetch';
import getAllMachineRequest from '../../Redux/Actions/machine';

class BookingDetail extends Component {
  constructor() {
    super();

    this.state = {
      dataDetail: {},
      rootStatus: '',
    };

    this.refSlider = React.createRef();
  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    this.props.loadingPage(true);

    this.props.getAllMachineRequest();

    this.fetchDataDetail().then((response) => {
      try {
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        this.setState({
          dataDetail: response,
          rootStatus: response.status,
        });
        return response;
      } catch (error) {
        console.log('fetchDataDetail', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  fetchDataDetail = async () => {
    const { match } = this.props;
    const { bookingId } = match.params;

    const result = await fetchABooking(bookingId);
    return result;
  }

  fetchUpdateBooking = async (bookingId, data) => {
    const result = await fetchUpdateBooking(bookingId, data);
    return result;
  }

  fetchCheckin = async (qrCode) => {
    const result = await fetchCheckin(qrCode);
    return result;
  }

  onClickUpdate = () => {
    const { dataDetail } = this.state;
    const { match } = this.props;
    const { bookingId } = match.params;
    // const returnDate = dataDetail.returnDate ? dataDetail.returnDate : date2UtcSecondEpoch(new Date());

    if ((!dataDetail.repairCosts && parseFloat(dataDetail.repairCosts) !== 0) || parseFloat(dataDetail.repairCosts) < 0) {
      toastr.error('Please enter a cost greater than or equal to zero!', 'Error');
      return;
    }

    const data = {
      repairCosts: parseFloat(dataDetail.repairCosts),
      note: dataDetail.note,
      status: dataDetail.status.toLowerCase(),
    };

    this.props.loadingPage(true);
    this.fetchUpdateBooking(bookingId, data).then((response) => {
      try {
        console.log('fetchUpdateBooking response ', response);
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        toastr.success('Update booking successful', 'Success');
        this.props.getAllBookingRequest();
        return response;
      } catch (error) {
        console.log('fetchUpdateBooking', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  onClickSkipCheckin = () => {
    const { dataDetail } = this.state;

    this.props.loadingPage(true);
    this.fetchCheckin(dataDetail.QRCode).then((response) => {
      try {
        console.log('fetchCheckin response ', response);
        if (response.error) {
          toastr.error(response.message, 'Error');
          return null;
        }
        toastr.success('Checkin booking successful', 'Success');
        this.props.getAllBookingRequest();
        return response;
      } catch (error) {
        console.log('fetchCheckin', error);
        toastr.error(error, 'Error');
        return null;
      } finally {
        this.props.loadingPage(false);
      }
    });
  }

  onInputChange = (event) => {
    const { dataDetail } = this.state;
    const { name, value } = event.target;
    const clonedDataDetail = _.cloneDeep(dataDetail);
    clonedDataDetail[name] = value;

    this.setState({ dataDetail: clonedDataDetail });
  }

  render = () => {
    const { dataDetail, rootStatus } = this.state;
    if (!dataDetail) {
      return null;
    }

    let isHiddenUpdateButton = true;
    let isHiddenSkipCheckinButton = true;
    if (rootStatus) {
      isHiddenUpdateButton = rootStatus.toLowerCase() !== 'booking';
      isHiddenSkipCheckinButton = rootStatus.toLowerCase() !== 'booking';
    }

    return (
      <div className="portal">
        <main className="main">
          <Container fluid>
            <ModalSlider ref={this.refSlider} title="title" />
            <Row className="booking-name">
              <Col xs="6" className="d-flex">
                {
                !this.props.location.state
                  ? (
                    <Link to={{
                      pathname: '/admin/bookings',
                      state: {
                        activeTab: '1',
                      },
                    }}
                    >
                      <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                    </Link>
                  )
                  : !this.props.location.state.isOptions
                    ? (
                      <Link to={this.props.location.state.oldPathname}>
                        <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                      </Link>
                    )
                    : (
                      <Link to={{
                        pathname: this.props.location.state.oldPathname,
                        state: { activeTab: '2' },
                      }}
                      >
                        <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
                      </Link>
                    )
              }
                <h2 className="mb-3 text__header">
                BOOKING
                </h2>
                <h3>
                  {
                  dataDetail
                    ? dataDetail.index
                    : ''
                }
                </h3>
              </Col>
              <Col xs="6" className="align-self-center text-right">
                <Button
                  className="btn mr-3"
                  onClick={this.onClickSkipCheckin}
                  hidden={isHiddenSkipCheckinButton}
                >
                  Skip Checkin
                </Button>
                <Button
                  className="btn"
                  onClick={this.onClickUpdate}
                  hidden={isHiddenUpdateButton}
                >
                  Update
                </Button>
              </Col>
            </Row>
            <BookingInfo
              dataBookingInfo={dataDetail}
              rootStatus={rootStatus}
              onChangedBookingInfo={this.onInputChange}
              machine={this.props.machines[dataDetail.serialMachine]}
            />
          </Container>
        </main>
      </div>
    );
  }
}

BookingDetail.defaultProps = {
  match: {
    params: {
      qrCode: '',
    },
  },
};

BookingDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape(),
  }).isRequired,
  loadingPage: PropTypes.func.isRequired,
  getAllBookingRequest: PropTypes.func.isRequired,
  getAllMachineRequest: PropTypes.func.isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ machines }) => ({
  machines,
});

const mapDispatchToProps = {
  loadingPage,
  getAllBookingRequest,
  getAllMachineRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);
