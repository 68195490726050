import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BootstrapTable,
  TableHeaderColumn,
  SizePerPageDropDown,
} from 'react-bootstrap-table';
import {
  Button,
  Col,
  Row,
  Input,
} from 'reactstrap';
import moment from 'moment';
import {
  isEmpty,
  forEach,
  get,
  startCase,
} from 'lodash';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import toast from 'toastr';

import { generateList } from '../../utils';
import DeleteModal from '../DeleteModal';

const KEYS_POSITION = Array.from({ length: 50 }, (id, index) => ({
  label: index + 1,
  value: index + 1,
}));

const ListLocks = ({
  machines,
  locksMachine: {
    locks,
    lockIds,
    positionOccuppied,
  },
  onDeleteLock,
  onUpdateLock,
  onGetPositionUnavailable,
}) => {
  const [search, setSearch] = useState('');
  const [idLockEdit, setIdLockEdit] = useState(null);
  const [idLockDelete, setIdLockDelete] = useState(null);
  const [updateLockData, setUpdateLockData] = useState({});

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const renderPageList = () => {
    const data = [];

    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    return listPage;
  };

  const renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => console.log('toggle')}
    />
  );

  const renderNo = (cell, { index }) => <div>{index + 1}</div>;

  const onChangeKeyPosition = ({ value }) => {
    setUpdateLockData({
      ...updateLockData,
      keyPosition: value,
    });
  };

  const formatPosition = (key, serial) => {
    const { serialMachine } = updateLockData;

    if (!serialMachine || isEmpty(positionOccuppied)) return [];

    const _positionOccuppied = positionOccuppied[serialMachine];

    if (isEmpty(_positionOccuppied)) return KEYS_POSITION;

    if (!!key && serialMachine === serial) {
      return KEYS_POSITION.filter(
        ({ value }) => !_positionOccuppied.includes(value) || value === key
      );
    }

    return KEYS_POSITION.filter(({ value }) => !_positionOccuppied.includes(value));
  };

  const renderKeyPosition = (cell, { keyPosition: defaultKeyPosition, id, serialMachine: defaultSerialMachine }) => {
    const { serialMachine: currentSerialMachine, keyPosition: currentKeyPosition } = updateLockData;
    const isEditThisLock = idLockEdit === id;
    const isDisabled = !isEditThisLock || !currentSerialMachine;
    const value = isEditThisLock ? (
      currentKeyPosition || 'Select position'
    ) : (
      defaultKeyPosition || 'Select position'
    );
    return (
      <Select
        menuPortalTarget={document.body}
        menuPosition="fixed"
        options={formatPosition(defaultKeyPosition, defaultSerialMachine)}
        defaultValue={{ value: defaultKeyPosition, label: defaultKeyPosition || 'Select position' }}
        isDisabled={isDisabled}
        menuPlacement="bottom"
        onChange={onChangeKeyPosition}
        placeholder="Select position"
        value={{ value, label: value }}
      />
    );
  };

  const renderAssignDate = (cell, { assignDate }) => {
    const isDateValid = assignDate && moment(assignDate).isValid();
    return (
      <div>
        {isDateValid ? moment(assignDate * 1000).format('YYYY-MM-DD') : ''}
      </div>
    );
  };

  const ClickEdit = ({ id, serialMachine: defaultSerialMachine, keyPosition: defaultKeyPosition }) => () => {
    const { serialMachine: currentSerialMachine, keyPosition: currentKeyPosition } = updateLockData;
    if (id === idLockEdit) {
      if (currentSerialMachine === defaultSerialMachine && currentKeyPosition === defaultKeyPosition) {
        toast.warning('Nothing change!');
      } else {
        const body = {};
        if (!isEmpty(currentSerialMachine)) {
          body.serialMachine = currentSerialMachine;
        }
        if (currentKeyPosition) {
          body.keyPosition = currentKeyPosition;
        }
        if (!currentKeyPosition && currentSerialMachine !== defaultSerialMachine) {
          body.keyPosition = null;
        }

        onUpdateLock(id, body);
      }

      setIdLockEdit(null);
      setUpdateLockData({});
      return;
    }
    setIdLockEdit(id);
    setUpdateLockData({ serialMachine: defaultSerialMachine, keyPosition: defaultKeyPosition });

    onGetPositionUnavailable(defaultSerialMachine);
  };

  const handleDeleteLock = () => {
    onDeleteLock(idLockDelete);
    setIdLockDelete(null);
  };

  const onOpenModalDelete = idLock => () => setIdLockDelete(idLock);
  const onCloseModalDelete = () => setIdLockDelete(null);

  const renderAction = (cell, {
    id, status, serialMachine, keyPosition, bookingStatus,
  }) => {
    const isEdit = idLockEdit === id;
    const idDisableDelete = status === 'assigned_to_booking';
    const isDisableEdit = status === 'assigned_to_booking' && bookingStatus !== 'booking';

    return (
      <div>
        <Button
          className={`mr-2 ${isEdit ? 'btn-save' : 'btn-edit'}`}
          onClick={ClickEdit({ id, serialMachine, keyPosition })}
          disabled={isDisableEdit}
        >
          {isEdit ? 'Save' : 'Edit'}
        </Button>
        <Button
          className="btn-delete"
          onClick={onOpenModalDelete(id)}
          disabled={idDisableDelete}
        >
          Delete
        </Button>
      </div>
    );
  };

  const data = isEmpty(lockIds) ? [] : lockIds.map((lockId) => {
    const {
      id,
      keyPosition,
      assignDate,
      status,
      regPlate,
      serialMachine,
      machineName,
    } = locks[lockId];

    return {
      id,
      keyPosition,
      assignDate,
      regPlate,
      status,
      serialMachine,
      bookingCustomer: get(locks[lockId], 'booking.customerName', ''),
      bookingStatus: get(locks[lockId], 'booking.status', ''),
      bookingId: get(locks[lockId], 'booking.id', ''),
      machineName,
    };
  });
  const dataFilter = generateList(data, search);

  const renderbookingCustomer = (col, { bookingId, bookingStatus, bookingCustomer }) => {
    const status = bookingStatus === 'booking' ? 'new' : bookingStatus;

    return (
      <Link to={`/admin/bookings/${status}/${bookingId}`} style={{ color: '#F1C52E' }}>
        {bookingCustomer}
      </Link>
    );
  };

  const formatMachines = () => {
    if (isEmpty(machines)) return [];

    return Object.keys(machines).map(key => ({
      label: machines[key].name,
      value: machines[key].serialMachine,
    }));
  };

  const onChangeMachine = (defaultSerialMachine, defaultPosition, value) => {
    onGetPositionUnavailable(value);

    if (defaultSerialMachine === value) {
      setUpdateLockData({
        ...updateLockData,
        serialMachine: value,
        keyPosition: defaultPosition,
      });
      return;
    }

    setUpdateLockData({
      ...updateLockData,
      serialMachine: value,
      keyPosition: null,
    });
  };

  const renderMachine = (col, {
    serialMachine, id, keyPosition, status, machineName,
  }) => {
    const isDisabled = idLockEdit !== id || status === 'assigned_to_booking';

    const machine = machines[serialMachine] || {};
    const label = machineName || machine.name;

    return (
      <Select
        menuPortalTarget={document.body}
        menuPosition="fixed"
        options={formatMachines()}
        defaultValue={{ value: serialMachine, label: label || 'Select machine' }}
        isDisabled={isDisabled}
        menuPlacement="bottom"
        onChange={({ value }) => onChangeMachine(serialMachine, keyPosition, value)}
        placeholder="Select machine"
      />
    );
  };

  const renderLockStatus = (col, { status }) => (
    <div>
      {startCase(status)}
    </div>
  );

  return (
    <div className="users">
      <BootstrapTable
        data={dataFilter}
        options={{
          sizePerPageList: renderPageList(),
          sortIndicator: true,
          hidePageListOnlyOnePage: true,
          sizePerPageDropDown: renderSizePerPageDropDown,
        }}
        pagination
        striped
        multiColumnSort={3}
      >
        <TableHeaderColumn
          isKey
          dataField="id"
          dataAlign="center"
          dataFormat={renderNo}
          width="50"
        >
          No
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="regPlate"
          dataAlign="center"
        >
          Reg.Plate
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderMachine}
        >
          Machine Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderKeyPosition}
        >
          Position
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderbookingCustomer}
        >
          Booking Customer
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderAssignDate}
        >
          Assign Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderLockStatus}
        >
          Status
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          dataFormat={renderAction}
          width="180"
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable>
      <DeleteModal
        isOpen={!!idLockDelete}
        onClose={onCloseModalDelete}
        onDelete={handleDeleteLock}
        title="Delete Lock"
        submitText="Delete Lock"
        description={`Are you sure delete ${idLockDelete ? locks[idLockDelete].regPlate : ''}?`}
      />
    </div>
  );
};

ListLocks.defaultProps = {
  machines: {},
};

ListLocks.propTypes = {
  machines: PropTypes.objectOf(PropTypes.object),
  locksMachine: PropTypes.shape({
    locks: PropTypes.objectOf(PropTypes.object),
    lockIds: PropTypes.array,
    positionOccuppied: PropTypes.objectOf(PropTypes.array),
  }).isRequired,
  onDeleteLock: PropTypes.func.isRequired,
  onUpdateLock: PropTypes.func.isRequired,
  onGetPositionUnavailable: PropTypes.func.isRequired,
};

export default ListLocks;
