import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import setSearchRequest from '../../Redux/Actions/search';
import { generateList, addSearchValues, formatDisplayDate } from '../../utils';
import { renderLock } from './index';

library.add(faSearch);

class Done extends Component {
  state = { search: '' }

  onChangeSearch = (e) => {
    const { value } = e.target;

    this.setState({ search: value });

    if (this.searchTimer !== null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.props.setSearchRequest({ bookingSearchString: value });
    }, 500);
  }

  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderPageList = () => {
    const { data } = this.props;
    const listPage = [];

    if (!data || data.length <= 5) {
      return listPage;
    }

    _.forEach([5, 10, 20, 30], (n) => {
      if (n < data.length || (n - data.length) / 5 < 2) {
        const element = {
          text: `${n} rows`,
          value: n,
        };

        listPage.push(element);
      }
    });

    const element = {
      text: 'All',
      value: data.length,
    };

    listPage.push(element);

    return listPage;
  }

  renderSizePerPageDropDown = props => (
    <SizePerPageDropDown
      open={props.open}
      variation="dropup"
      onClick={() => this.onToggleDropDown(props.toggleDropDown)}
    />
  )

  render = () => {
    const { search } = this.state;
    const {
      data, history,
    } = this.props;

    const dataFilter = generateList(data, search);

    if (data) {
      _.forEach(data, (value) => {
        addSearchValues(value, value,
          ['bookingIndex', 'customerName', 'phoneNumber', 'plateNumber', 'returnDate']);
      });
    }

    return (
      <div className="table-tab">
        <InputGroup
          className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm input-search__remove-border"
        >
          <Input
            type="text"
            placeholder="Search"
            onChange={this.onChangeSearch}
            defaultValue={search}
          />
          <InputGroupAddon className="input-group-addon input-search__overlap" addonType="prepend">
            <FontAwesomeIcon icon="search" />
          </InputGroupAddon>
        </InputGroup>
        <BootstrapTable
          data={_.orderBy(dataFilter, ['bookingIndex'], ['desc'])}
          className="cursor-pointer__table"
          options={{
            sizePerPageList: this.renderPageList(),
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            paginationSize: 4,
            onRowClick(row) {
              history.push(`../admin/bookings/done/${row.bookingId}`);
            },
          }}
          pagination
          striped
          hover
          multiColumnSort={3}
        >
          <TableHeaderColumn
            isKey
            dataField="bookingIndex"
            dataAlign="center"
            dataSort
            width="100"
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="customerName"
            dataAlign="center"
            dataSort
            width="200"
          >
            Customer Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phoneNumber"
            dataAlign="center"
            dataSort
            width="200"
          >
            Phone Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataSort
            width="200"
            dataFormat={renderLock}
          >
            Lock (Plate Number)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="returnDate"
            dataAlign="center"
            dataSort
            width="200"
            dataFormat={(cell, row) => formatDisplayDate(cell)}
          >
            Return Date
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

Done.defaultProps = {
  data: [],
};

Done.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setSearchRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setSearchRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(Done));
