import { fetchClientWithToken, fetchClient } from './fetch-client';
import {
  MACHINE_URL, BOOKING_URL, LOGIN_URL,
  ALL_OCCUPPIED_LOCK_URL, CHECKIN,
  REG_PLATE, LOCKS, LOCK,
} from '../../config';
import auth from './auth';
import { memorizeMachines } from '../../utils/memorize';

function fetchWithToken(url, method, body = undefined) {
  try {
    const options = {
      url,
      method,
      body,
      token: auth.token(),
    };

    return fetchClientWithToken(options);
  } catch (error) {
    console.log(error);

    return null;
  }
}

function fetchWithoutToken(url, method, body = undefined) {
  try {
    const options = {
      url,
      method,
      body,
    };

    return fetchClient(options);
  } catch (error) {
    console.log(error);

    return null;
  }
}

export function fetchLogin(body) {
  return fetchWithoutToken(LOGIN_URL, 'POST', body);
}

export function fetchAllBooking() {
  return fetchWithToken(BOOKING_URL, 'GET');
}

export function fetchABooking(bookingId) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'GET');
}

export function fetchABookingQR(qrCode) {
  return fetchWithToken(`${BOOKING_URL}/QRCode/${qrCode}`, 'GET');
}

export function fetchCreateBooking(body) {
  return fetchWithToken(`${BOOKING_URL}`, 'POST', body);
}

export function fetchUpdateBooking(bookingId, body) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'PATCH', body);
}

export function fetchDeleteBooking(bookingId) {
  return fetchWithToken(`${BOOKING_URL}/${bookingId}`, 'DELETE');
}

export function fetchAllMachine() {
  return fetchWithToken(MACHINE_URL, 'GET');
}

export function fetchAMachine(machineId) {
  return fetchWithToken(`${MACHINE_URL}/${machineId}`, 'GET');
}

export function fetchCreateMachine(body) {
  return fetchWithToken(`${MACHINE_URL}`, 'POST', body);
}

export function fetchAllOccuppiedLock(serialMachine, dateFilter) {
  const url = `${ALL_OCCUPPIED_LOCK_URL}serialMachine=${serialMachine}&dateFilter=${dateFilter}`;
  return fetchWithToken(url, 'GET');
}

export function fetchCheckin(qrCode) {
  return fetchWithoutToken(`${CHECKIN}/${qrCode}`, 'PATCH');
}

export const fetchAll = async () => {
  try {
    const allBookingResult = await fetchAllBooking();
    const allMachineResult = await fetchAllMachine();
    // const allOccuppiedLockResult = await fetchAllOccuppiedLock();

    const bookings = allBookingResult != null ? allBookingResult : [];
    const machines = allMachineResult != null ? allMachineResult : [];
    // const occuppiedLocks = allOccuppiedLockResult != null ? allOccuppiedLockResult : [];

    return {
      bookings,
      machines: memorizeMachines(machines),
      // occuppiedLocks,
    };
  } catch (error) {
    console.log('fetchAll: ', error);

    return null;
  }
};

export const getAllRegPLateService = () => {
  return fetchWithToken(REG_PLATE, 'GET');
};

export const getAllLocksOfMachineService = () => fetchWithToken(LOCKS, 'GET');

export const getPositionUnavailableService = serialMachine => fetchWithToken(
  `${LOCKS}/position/occuppied?serial_machine=${serialMachine}`, 'GET',
);

export const createLockService = lock => fetchWithToken(
  `${LOCK}/create`, 'POST', lock,
);

export const deleteLockService = idLock => fetchWithToken(
  `${LOCK}/${idLock}/delete`, 'DELETE',
);

export const getLockAvailableService = serialMachine => fetchWithToken(
  `${LOCKS}/available?serial_machine=${serialMachine}`, 'GET',
);

export const updateLockService = (lockId, lock) => fetchWithToken(
  `${LOCK}/${lockId}/update`, 'PUT', lock,
);

export const getLocksOccupiedService = serialMachine => fetchWithToken(
  `${LOCKS}/machine/occuppied?serial_machine=${serialMachine}`, 'GET',
);

export default fetchAll;
