import initialState from './initialState';
import AppFlowActions from '../../constants';

export default function (state = initialState.locks, action) {
  switch (action.type) {
    case AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_COMPLETE: {
      const { locks, lockIds } = action.payload;
      return {
        ...state,
        locks,
        lockIds,
      };
    }
    case AppFlowActions.GET_POSTION_UNAVAILABLE_COMPLETE: {
      const { serialMachine, positionOccuppied } = action.payload;
      return {
        ...state,
        positionOccuppied: {
          ...state.positionOccuppied,
          [serialMachine]: positionOccuppied,
        },
      };
    }
    case AppFlowActions.GET_LOCK_AVAILABLE_COMPLETE: {
      const { serialMachine, locksAvailable } = action.payload;
      return {
        ...state,
        locksAvailable: {
          ...state.locksAvailable,
          [serialMachine]: locksAvailable,
        },
      };
    }
    case AppFlowActions.GET_LOCKS_OCCUPIED_COMPLETE: {
      const { serialMachine, locksOccupied } = action.payload;
      return {
        ...state,
        locksOccupied: {
          ...state.locksOccupied,
          [serialMachine]: locksOccupied,
        },
      };
    }
    default:
      return state;
  }
}
