import { combineReducers } from 'redux';

import initialState from './initialState';
import auth from '../Helpers/auth';
import AppFlowActions from '../../constants';
import login from './login';
import loading from './loading';
import bookings from './booking';
import occuppiedLocks from './occuppiedLock';
import machines from './machine';
import search from './search';
import processGetAllApi from './getall';
import processReload from './reload';
import tracks from './tracks';
import regPlates from './regPlates';
import locks from './locks';

const appReducer = combineReducers({
  login,
  loading,
  bookings,
  occuppiedLocks,
  machines,
  search,
  tracks,
  regPlates,
  locks,
});

function rootReducer(state, action) {
  if (action.type === AppFlowActions.LOGOUT_REQUEST) {
    auth.logout();

    return initialState;
  }

  if (action.type === AppFlowActions.GET_ALL_DATA_COMPLETE) {
    return processGetAllApi(state, action);
  }

  if (action.type === AppFlowActions.RELOAD_PAGE_REQUEST) {
    return processReload(state, action);
  }

  return appReducer(state, action);
}

export default rootReducer;
