import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
  CardGroup, Card, CardBody,
  Input, InputGroup, InputGroupAddon,
  Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import toastr from 'toastr';

import iconUser from '../image/user.svg';
import iconLock from '../image/lock.svg';
import logo from '../includes/images/logo_mobile.png';

import { loginRequest } from '../Redux/Actions/login';
import loadingPage from '../Redux/Actions/loading';

class Login extends Component {
  constructor() {
    super();

    this.state = { isAlert: false };

    this.loginData = {
      userName: '',
      password: '',
    };

    this.refModal = React.createRef();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { login, history } = nextProps;

    if (_.isEmpty(login)) {
      return { login };
    }

    if (login !== prevState.login) {
      if (login.token) {
        history.push('../admin/machines');
      } else {
        nextProps.loadingPage(false);
        toastr.error(login.error.message, 'Failed');

        return {
          login,
          isAlert: true,
        };
      }
    }

    return null;
  }

  onChange = (e) => {
    const { value, name } = e.target;

    this.loginData[name] = value;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { userName, password } = this.loginData;
    if (userName === 'admin') {
      this.props.loginRequest({ userName, password });
      this.props.loadingPage(true);
    }
  }

  render = () => {
    const { isAlert } = this.state;

    return (
      <div className="login app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>
                      Login
                    </h1>
                    <p className="text-muted">
                      Sign In to your account
                    </p>
                    <form onSubmit={this.onSubmit}>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <img src={iconUser} alt="user" />
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Username"
                          name="userName"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <img src={iconLock} alt="lock" />
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <span className="help-block" style={{ color: '#ff2121' }}>
                          {isAlert ? 'Invalid credentials' : ' '}
                        </span>
                      </InputGroup>
                      <Row>
                        <Col xs="12" sm="6">
                          <Button type="submit" className="px-4 btn">
                            Login
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
                <Card
                  className="text-white bg-primary bg-primary-edit py-5 p-4 d-md-down-none"
                >
                  <div className="text-center signup-box">
                    {/* <img src={logo} /> */}
                    <img src={logo} alt="logo" />
                  </div>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

const mapDispatchToProps = {
  loginRequest,
  loadingPage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
