import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import Slider from 'react-slick';
import _ from 'lodash';
import moment from 'moment';

import arrowUpIcon from '../../image/arrow-up.svg';
import arrowDownIcon from '../../image/arrow-down.svg';

class ModalSlider extends Component {
  state = { modal: false }

  // componentDidMount() {
  //   this.enableSwipeToSlide();
  // }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { photos, title } = nextProps;
    const { lastPhotos, lastTitle } = prevState;

    if (lastPhotos && lastPhotos !== photos || lastTitle && lastTitle !== title) {
      return ({
        title,
        photos,
        lastPhotos: photos,
        lastTitle: title,
      });
    }

    return ({
      lastPhotos: photos,
      lastTitle: title,
    });
  }

  toggleModal = () => {
    const { modal } = this.state;

    this.setState({
      modal: !modal,
    });
  }

  toggleArrow = (photos, index) => {
    photos[index].isShowNote = !photos[index].isShowNote;

    this.setState({ photos });
  }

  // enableSwipeToSlide = () => {
  //   if (window.innerWidth <= 768) {
  //     const settings = {
  //       dots: true,
  //       infinite: true,
  //       speed: 300,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       swipe: true,
  //       swipeToSlide: true,
  //     };

  //     this.setState({
  //       settings,
  //     });
  //   } else {
  //     const settings = {
  //       dots: true,
  //       infinite: true,
  //       speed: 300,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       // swipe: false,
  //       // swipeToSlide: false,
  //       fade: true,
  //       cssEase: 'linear',
  //       // draggable: false,
  //     };

  //     this.setState({
  //       settings,
  //     });
  //   }

  //   window.addEventListener('resize', () => {
  //     if (window.innerWidth <= 768) {
  //       const settings = {
  //         dots: true,
  //         infinite: true,
  //         speed: 300,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         swipe: true,
  //         swipeToSlide: true,
  //       };

  //       this.setState({
  //         settings,
  //       });
  //     } else {
  //       const settings = {
  //         dots: true,
  //         infinite: true,
  //         speed: 300,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         // swipe: false,
  //         // swipeToSlide: false,
  //         fade: true,
  //         cssEase: 'linear',
  //         // draggable: false,
  //       };

  //       this.setState({
  //         settings,
  //       });
  //     }
  //   });
  // }

  renderImageSlide = (title, photos) => {
    if (photos !== null) {
      return (
        _.map(photos, (value, index) => (
          <div key={value.url} className="photo-container">
            <p className="text-center text__title">
              {value.titlePath}
            </p>
            <div className={title === 'Check-in photo' || title === 'Check-out photo'
              ? 'photo-image--user'
              : 'photo-image--car'}
            >
              <img src={value.url} alt="" />
            </div>
            {title === 'Accident photos'
              ? (
                <div>
                  <div className="image-date image-date--accident">
                    {value.imageDate ? moment(value.imageDate).format('DD-MM-YYYY, HH:mm:SS') : ''}
                  </div>
                  <div
                    className={value.isShowNote === true ? 'comment' : 'comment comment--minimized'}
                  >
                    <div>
                      <b className="comment-text">
                        Note:
                      </b>
                      <img
                        src={value.isShowNote === true ? arrowDownIcon : arrowUpIcon}
                        alt="arrow"
                        onClick={() => this.toggleArrow(photos, index)}
                        role="presentation"
                        className="icon-arrow"
                      />
                    </div>
                    <div className="comment-note">
                      {value.comment
                        ? value.comment
                        : (
                          <i>
                            Empty note
                          </i>
                        )}
                    </div>
                  </div>
                </div>
              )
              : title !== 'Check-in photo' && title !== 'Check-out photo' ? (
                <div className="image-date image-date--normal">
                  {value.imageDate ? moment(Number(value.imageDate)).format('DD-MM-YYYY, HH:mm:SS') : ''}
                </div>
              )
                : (
                  <div className="image-date image-date--checkinout">
                    {value.imageDate ? moment(value.imageDate).format('DD-MM-YYYY, HH:mm:SS') : ''}
                  </div>
                )}
          </div>
        ))
      );
    }

    return null;
  }

  render = () => {
    const { modal } = this.state;
    const { title, photos } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      // draggable: true,
      // swipe: true,
      // swipeToSlide: true,
      // fade: true,
      // cssEase: 'linear',
    };

    return (
      <div className="slider">
        <Modal
          className={title === 'Check-in photo' || title === 'Check-out photo'
            ? 'slider__modal slider__modal--user'
            : 'slider__modal slider__modal--car'}
          isOpen={modal}
          toggle={this.toggleModal}
        >
          <ModalHeader className="slider__header">
            {title}
          </ModalHeader>
          <ModalBody>
            <Slider {...settings}>
              {modal ? this.renderImageSlide(title, photos) : ''}
            </Slider>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ModalSlider.defaultProps = {
  match: {
    params: {
      bookingId: '',
    },
  },
  title: '',
  images: {},
  photos: [],
};

ModalSlider.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  images: PropTypes.shape({
    accidentPhoto: PropTypes.object,
  }),
  photos: PropTypes.arrayOf(PropTypes.object),
};

export default ModalSlider;
