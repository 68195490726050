import _ from 'lodash';

import initialState from './initialState';
import AppFlowActions from '../../constants';

function getAMachine(state, action) {
  const { data } = action;
  // const machine = _.find(state, x => x._id === data.id);
  console.log('Reducers getAMachine data ', state);
  console.log('Reducers getAMachine data ', data);
  // console.log('Reducers getAMachine machine ', machine);
  // if (machine) {
  //   machine.vehicleInfo = data.idCar;
  // }
}

const machines = (state = initialState.machines, action) => {
  switch (action.type) {
    case AppFlowActions.GET_ALL_MACHINE_COMPLETE:
      if (action.machines) {
        const { machines } = action;

        return machines;
      }

      return state;
    case AppFlowActions.GET_A_MACHINE_COMPLETE:
    {
      return getAMachine(state, action);
    }

    case AppFlowActions.START_CONFERENCE: {
      const { serialMachine } = action;

      if (serialMachine) {
        return {
          ...state,
          [serialMachine]: {
            ...state[serialMachine],
            isOpenStream: true,
          },
        };
      }

      return state;
    }
    case AppFlowActions.STOP_CONFERENCE: {
      const { serialMachine } = action;

      if (serialMachine) {
        return {
          ...state,
          [serialMachine]: {
            ...state[serialMachine],
            isOpenStream: false,
          },
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default machines;
