const { localStorage } = global.window;

const auth = {
  login(data) {
    const { token } = data;

    localStorage.token = token;
  },

  token() {
    return localStorage.token;
  },

  logout() {
    localStorage.clear();
  },
};

export default auth;
