import React, { Component } from 'react';
import {
  Nav, Navbar, NavItem, NavLink, NavbarBrand,
} from 'reactstrap';

import logo from '../includes/images/logo.png';

class TermsConditions extends Component {
  render = () => (
    <div className="ts">
      <header className="ts__header">
        <Navbar expand="md">
          <NavbarBrand href="">
            <img src={logo} alt="Logo" className="ts__logo" />
          </NavbarBrand>
          <Nav className="ml-auto ts__navbar" navbar>
            <NavItem>
              <NavLink href="/login">
                Login
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <div className="ts__header--heading">
          <strong>
            Terms and Conditions
          </strong>
        </div>
        <div className="ts__header--content" />
      </header>
      <section className="ts__section mt-3">
        <h1>
          <strong>
            Our Terms and Conditions
          </strong>
        </h1>
        <p>
          Here by acknowledge that during the currency of this agreement, for the purposes of section 66 of the Road Traffic Offenders Act 1988, and the Road Traffic Act 1991, and Schedule 1 to the Road Traffic (Owner liability) (Scotland) Regulations 1975 (all as amended or replaced by any subsequent legislation or orders), I shall be liable as owner of the above vehicle in respect of any of the offenses and any excess charge or Penalty Charge referred to in any suchlegislation or orders in accordance with the conditions attached. I acknowledge that my liability shall extended to any other vehicle hired under the same agreement and to any period by which the original period of hire may be extended. I hereby agree to hire the above vehicle, on the terms and conditions set out herein, attached (a copy of which can be inspected at our office) and on the insurance policy. Here by acknowledge that during the currency of this agreement, for the purposes of section 66 of the Road Traffic Offenders Act 1988, and the Road Traffic Act 1991, and Schedule 1 to the Road Traffic (Owner liability) (Scotland) Regulations 1975 (all as amended or replaced by any subsequent legislation or orders), I shall be liable as owner of the above vehicle in respect of any of the offenses and any excess charge or Penalty Charge referred to in any suchlegislation or orders in accordance with the conditions attached. I acknowledge that my liability shall extended to any other vehicle hired under the same agreement and to any period by which the original period of hire may be extended. I hereby agree to hire the above vehicle, on the terms and conditions set out herein, attached (a copy of which can be inspected at our office) and on the insurance policy.
        </p>
      </section>
      <footer className="ts__footer">
        <span className="ts__footer--content">
          &copy; 2017-2018 Service Automatics SC Ltd. All rights reserved
        </span>
      </footer>
    </div>
  )
}

export default TermsConditions;
