import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import loadingPage from '../../Redux/Actions/loading';
import getAllMachineRequest from '../../Redux/Actions/machine';
import {
  getAllLocksOfMachineAction,
  deleteLockAction,
  updateLockAction,
  getPositionUnavailableAction,
} from '../../Redux/Actions/locks';

import ListLocks from '../../components/ListLocks';

const TABS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'available',
    label: 'Available',
  },
  {
    value: 'assigned_to_booking',
    label: 'Assigned to booking',
  },
  {
    value: 'out_of_machine',
    label: 'Out of machine',
  },
];

function PortalLocks({
  machines,
  locks,
  onGetAllMachine,
  onGetAllLocksOfMachine,
  history,
  onDeleteLock,
  onUpdateLock,
  onGetPositionUnavailable,
}) {
  const [activeTab, setActiveTab] = useState(TABS[0].value);

  useEffect(() => {
    onGetAllMachine();

    onGetAllLocksOfMachine();
  }, []);

  const onClickCreate = () => history.push('/admin/createlock');

  const toggleTab = tab => () => setActiveTab(tab);

  const getLockMachine = () => {
    if (activeTab === TABS[0].value) return locks;

    const { locks: _locks, lockIds, positionOccuppied } = locks;
    const _lockIds = lockIds.filter(lockId => (
      _locks[lockId].status === activeTab
    ));

    return {
      ...locks,
      ...positionOccuppied,
      lockIds: _lockIds,
    };
  };

  return (
    <div className="portal">
      <main className="main">
        <Container fluid>
          <Row>
            <Col xs="6">
              <h2 className="mt-3 mb-3 text__header">
                LOCKS MANAGEMENT
              </h2>
            </Col>
            <Col xs="6" className="align-self-center text-right">
              <Button
                className="btn"
                onClick={onClickCreate}
              >
                Create new lock
              </Button>
            </Col>
          </Row>
          <Nav tabs>
            {TABS.map(({ label, value }) => (
              <NavItem key={value}>
                <NavLink
                  className={classnames({ active: activeTab === value })}
                  onClick={toggleTab(value)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {TABS.map(({ value }) => (
              <TabPane tabId={value} key={value} style={{ paddingRight: 0, paddingLeft: 0 }}>
                <Row>
                  <Col xs="12">
                    <ListLocks
                      machines={machines}
                      locksMachine={getLockMachine()}
                      activeTab={activeTab}
                      onDeleteLock={onDeleteLock}
                      onUpdateLock={onUpdateLock}
                      onGetPositionUnavailable={onGetPositionUnavailable}
                    />
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </main>
    </div>
  );
}

PortalLocks.defaultProps = {
};

PortalLocks.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onGetAllLocksOfMachine: PropTypes.func.isRequired,
  onGetAllMachine: PropTypes.func.isRequired,
  locks: PropTypes.objectOf(PropTypes.shape({
    locks: PropTypes.objectOf(PropTypes.object),
    lockIds: PropTypes.array,
    positionOccuppied: PropTypes.objectOf(PropTypes.array),
    locksAvailable: PropTypes.objectOf(PropTypes.array),
  })).isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
  onDeleteLock: PropTypes.func.isRequired,
  onUpdateLock: PropTypes.func.isRequired,
  onGetPositionUnavailable: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  machines: state.machines,
  locks: state.locks,
});

const mapDispatchToProps = {
  onLoadingPage: loadingPage,
  onGetAllMachine: getAllMachineRequest,
  onGetAllLocksOfMachine: getAllLocksOfMachineAction,
  onDeleteLock: deleteLockAction,
  onUpdateLock: updateLockAction,
  onGetPositionUnavailable: getPositionUnavailableAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLocks));
