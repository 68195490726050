import initialState from './initialState';
import AppFlowActions from '../../constants';

const search = (state = initialState.search, action) => {
  switch (action.type) {
    case AppFlowActions.SET_SEARCH_COMPLETE:
    {
      const newInfo = action.data;
      const output = Object.assign(state, newInfo);

      return {
        ...output,
      };
    }
    default:
      return state;
  }
};

export default search;
