import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron,
  Col,
  Row,
  Label,
  Button,
  Input,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { isEmpty } from 'lodash';

import { checkNull } from '../../utils';

const POSTIONS = Array.from({ length: 50 }, (v, k) => ({
  label: k + 1,
  value: k + 1,
}));

function CreateLockInfo({
  locks,
  machines,
  onGetPositionUnavailable,
  onCreateLock,
  history,
}) {
  const [formData, setFormData] = useState({
    regPlate: '',
    serialMachine: '',
    keyPosition: '',
    invalidField: '',
  });

  const formatMachines = () => {
    if (isEmpty(machines)) return [];

    return Object.keys(machines).map(key => ({
      label: machines[key].name,
      value: machines[key].serialMachine,
      customAbbreviation: machines[key].status,
    }));
  };

  const formatPosition = () => {
    const { positionOccuppied } = locks;
    const { serialMachine } = formData;

    if (!serialMachine || isEmpty(positionOccuppied)) return [];

    const _positionOccuppied = positionOccuppied[serialMachine];

    if (isEmpty(_positionOccuppied)) return POSTIONS;

    return POSTIONS.filter(({ value }) => !_positionOccuppied.includes(value));
  };

  const handleSelectMachine = ({ value }) => {
    onGetPositionUnavailable(value);
    setFormData({
      ...formData,
      serialMachine: value,
      invalidField: '',
      keyPosition: null,
    });
  };

  const handleSelectKeyPosition = ({ value }) => {
    setFormData({
      ...formData,
      keyPosition: value,
      invalidField: '',
    });
  };

  const getInvalidStyleSelect = (field, checkField) => {
    if (field === checkField) {
      return {
        control: provided => ({
          ...provided,
          border: '1px solid #ff5454 !important',
          boxShadow: 'none !important',
        }),
      };
    }

    return {};
  };

  const onChange = ({ target: { value, name } }) => {
    setFormData({
      ...formData,
      [name]: value,
      invalidField: '',
    });
  };

  const onCreateClick = (e) => {
    const { regPlate, serialMachine, keyPosition } = formData;
    e.preventDefault();

    const dataCheckNull = [
      { field: 'regPlate', value: regPlate },
    ];

    const invalidField = checkNull(dataCheckNull);

    if (invalidField) {
      setFormData({
        ...formData,
        invalidField,
      });
      return;
    }

    const body = {
      keyPosition: keyPosition || null,
      serialMachine: serialMachine || null,
      regPlate,
    };

    onCreateLock(body, history);
  };

  const { invalidField, serialMachine } = formData;

  return (
    <Jumbotron className="create-booking__jumbotron --child">
      <Col md={{ offset: 2, size: 8 }}>
        <form
          className="create-booking__form mt-4 form-horizontal"
          onSubmit={onCreateClick}
        >
          <Row>
            <Col xs="12">
              <h2 className="text-center mb-4">
                <strong>
                  New lock information
                </strong>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: '10', offset: '1' }} xs="12">
              <FormGroup className="ml-0">
                <Label className="text__title text__title--info mb-1">
                  Reg.Plate
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className={invalidField === 'regPlate' ? 'is-invalid' : ''}
                  type="text"
                  name="regPlate"
                  placeholder="Reg Plate"
                  value={formData.regPlate}
                  onChange={onChange}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: '10', offset: '1' }} xs="12">
              <FormGroup className="ml-0">
                <Label className="text__title text__title--info mb-1">
                  Machine
                </Label>
                <Select
                  name="serialMachine"
                  options={formatMachines()}
                  placeholder="Machine"
                  onChange={handleSelectMachine}
                  menuPlacement="bottom"
                  styles={getInvalidStyleSelect(invalidField, 'serialMachine')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: '10', offset: '1' }} xs="12">
              <FormGroup className="ml-0">
                <Label className="text__title text__title--info mb-1">
                  Position
                </Label>
                <Select
                  name="keyPosition"
                  options={formatPosition()}
                  placeholder="Key position"
                  onChange={handleSelectKeyPosition}
                  menuPlacement="bottom"
                  styles={getInvalidStyleSelect(invalidField, 'keyPosition')}
                  isDisabled={!serialMachine}
                  value={{ value: formData.keyPosition, label: formData.keyPosition || 'Key position' }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-5 mb-4 justify-content-center">
            <Button
              className="create-booking__btn create-booking__btn-finish"
              onClick={onCreateClick}
            >
              Create new lock
            </Button>
          </Row>
        </form>
      </Col>
    </Jumbotron>
  );
}

CreateLockInfo.propTypes = {
  locks: PropTypes.objectOf(PropTypes.object).isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
  onGetPositionUnavailable: PropTypes.func.isRequired,
  onCreateLock: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CreateLockInfo;
