import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import _ from 'lodash';
import {
  Row, Col, UncontrolledTooltip,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import InputDatePicker from '../InputDatePicker';

import { fetchAllOccuppiedLock } from '../../Redux/Helpers/fetch';
import loadingPage from '../../Redux/Actions/loading';
import { date2UtcSecondEpoch } from '../../utils';

library.add(faCircle);

class KeyStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datePick: new Date(),
    };
  }

  componentDidMount = () => {
    const { dataMachines } = this.props;

    const dataSlot = this.createDataSlot();

    this.setState({ dataSlot });

    if (dataMachines && !_.isEmpty(dataMachines)) {
      const { serialMachine } = dataMachines;

      this.fetchKeyStatus(serialMachine, new Date());
    }
  }

  componentDidUpdate = (prevProps) => {
    const { dataMachines } = this.props;

    if (dataMachines && !_.isEmpty(dataMachines) && dataMachines !== prevProps.dataMachines) {
      const { serialMachine } = dataMachines;

      this.fetchKeyStatus(serialMachine, new Date());
    }
  }

  onChangeKeyStatus = (date) => {
    const { dataMachines } = this.props;
    this.setState({ datePick: date });
    this.fetchKeyStatus(dataMachines.serialMachine, date);
  }

  createDataSlot = () => {
    const slot = [];

    for (let i = 0; i < 5; i += 1) {
      slot.push([]);
    }

    for (let i = 0; i < slot.length; i += 1) {
      for (let j = 0; j < 10; j += 1) {
        const index = i * 10 + j + 1;

        slot[i].push({ position: index, status: '' });
      }
    }

    return slot;
  }

  fetchKeyStatus = async (serialMachine, date) => {
    try {
      this.props.loadingPage(true);
      const dataSlot = this.createDataSlot();

      return fetchAllOccuppiedLock(serialMachine, date2UtcSecondEpoch(date)).then((result) => {

        if (result.error) {
          this.props.loadingPage(false);

          toastr.error(result.message, 'Error');
        } else if (result.length === 0) {
          const slot = this.createDataSlot();

          this.setState({
            dataSlot: slot,
            datePick: date,
          });

          this.props.loadingPage(false);
        } else {
          const occuppiedList = [];

          if (result.length !== 0) {
            _.forEach(result, (value) => {
              occuppiedList.push({
                position: Number(value),
                status: 'occupied',
              });
            });
          }

          _.forEach(dataSlot, (value) => {
            _.forEach(value, (val) => {
              _.forEach(occuppiedList, (v) => {
                if (v.position === val.position) {
                  val.position = v.position;
                  val.status = v.status;
                }
              });
            });
          });

          this.setState({
            dataSlot,
            datePick: date,
          });

          this.props.loadingPage(false);
        }
      });
    } catch (error) {
      console.log('fetchKeyStatus', error);

      this.props.loadingPage(false);

      toastr.error('Fetch key status failed', 'Error');

      return null;
    }
  };

  renderKeyPositionItem = ({ position }) => {
    const { locksOccupied } = this.props;
    const lock = locksOccupied.find(item => item.keyPosition === position);

    if (_.isEmpty(lock)) {
      return (
        <div className="reg-plate" key={`id-${position}`}>
          <td id={`id-${position}`} className="text__content">
            {position}
          </td>
        </div>
      );
    }

    return (
      <div
        className="reg-plate reg-plate__occupied"
        key={`id-${position}`}
      >
        <td className="occupied text__content">
          {position}
        </td>
        <UncontrolledTooltip target={`id-${position}`}>
          {lock.regPlate}
        </UncontrolledTooltip>
        <span
          className="reg-plate__content"
          id={`id-${position}`}
        >
          {lock.regPlate}
        </span>
      </div>
    );
  }

  render = () => {
    const { datePick, dataSlot } = this.state;
    return (
      <div>
        <Row className="key-status" style={{ marginLeft: '0rem' }}>
          <div className="text__title text__title--info">
              Keys status:
          </div>
          <Col xs="auto" className="key-status__input">
            <DatePicker
              selected={datePick}
              dateFormat="dd-MM-yyyy"
              customInput={<InputDatePicker />}
              onChange={date => this.onChangeKeyStatus(date)}
            />
          </Col>
        </Row>
        <Row className="key-status__table">
          <table className="mt-5 mb-5">
            <tbody>
              {
                  _.map(dataSlot, (value, index) => (
                    <tr key={index}>
                      {_.map(value, item => (
                        this.renderKeyPositionItem(item)
                      ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </Row>
        <Row className="color-box">
          <FontAwesomeIcon icon="circle" className="color-box--occupied" />
          <span className="color-box--text text__content">
              Occupied
          </span>
          <FontAwesomeIcon icon="circle" className="color-box--available" />
          <span className="color-box--text text__content">
              Available
          </span>
        </Row>
      </div>
    );
  }
}

KeyStatus.defaultProps = {
  dataMachines: {},
  locksOccupied: [],
};

KeyStatus.propTypes = {
  loadingPage: PropTypes.func.isRequired,
  dataMachines: PropTypes.shape(),
  locksOccupied: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
  return {
    loading: state.loading,
  };
}

const mapDispatchToProps = {
  loadingPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyStatus);
