import emitter from '../../utils/emitter';
import AppFlowActions from '../../constants';

async function fetchRequest(myRequest) {
  const response = await fetch(myRequest);

  if (response.status === 401) {
    emitter.emit(AppFlowActions.LOGOUT_REQUEST, 'request');
    return 'Token error!';
  }

  if (response.status === 200 || response.status === 400) {
    const body = await response.json();
    return body;
  }

  return 'Fetch request error!';
}

export function fetchClient({ url, method, body }) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const options = {
    method,
    body: JSON.stringify(body),
    headers: defaultHeaders,
  };
  console.log(`fetchClient url: ${url} options `, options);

  const request = new Request(url, options);

  return fetchRequest(request);
}

export function fetchClientWithToken({
  url, method, body, token,
}) {
  // const authorization = `Bearer ${token}`;
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const options = {
    method,
    body: JSON.stringify(body),
    headers: defaultHeaders,
  };
  console.log(`fetchClientWithToken url: ${url} options `, options);
  const request = new Request(url, options);

  return fetchRequest(request);
}

export function fetchClientFile({ url, method, body }) {
  const options = {
    method,
    body,
  };
  const request = new Request(url, options);

  return fetchRequest(request);
}

export default fetchClient;
