import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

import New from '../../components/Bookings/New';
import CheckedIn from '../../components/Bookings/CheckedIn';
import Done from '../../components/Bookings/Done';

class PortalBookings extends Component {
  state = { activeTab: '1' }

  componentDidMount = () => {
    const { bookings } = this.props;

    const dataTable = this.loadData(bookings);

    if (this.props.location.state) {
      const { activeTab } = this.props.location.state;
      if (activeTab) {
        this.setState({ activeTab });

        window.history.replaceState(null, null, window.location.pathname);
      }
    }

    this.setState({
      dataNew: dataTable.dataNew,
      dataCheckedin: dataTable.dataCheckedin,
      dataDone: dataTable.dataDone,
    });
  }

  componentDidUpdate = (prevProps) => {
    const { bookings } = this.props;

    if (bookings !== prevProps.bookings) {
      const dataTable = this.loadData(bookings);

      this.setState({
        dataNew: dataTable.dataNew,
        dataCheckedin: dataTable.dataCheckedin,
        dataDone: dataTable.dataDone,
      });
    }
  }

  loadData = (bookings) => {
    const dataNew = [];
    const dataCheckedin = [];
    const dataDone = [];

    _.forEach(bookings, (element) => {
      const data = {
        bookingIndex: element.index,
        bookingId: element.id,
        status: element.status,
        bookingDate: element.bookingDate,
        assignDate: element.assignDate,
        returnDate: element.returnDate,
        doneDate: element.doneDate,
        checkoutDate: element.checkoutDate,
        plateNumber: element.plateNumber,
        customerName: element.customerName,
        repairCosts: element.repairCosts,
        checkedInDate: element.checkedInDate,
        checkInDate: element.checkInDate,
        repairNotes: element.repairNotes,
        machineName: element.machineName,
        bill: element.bill,
        demageDescription: element.demageDescription,
        QRCode: element.QRCode,
        phoneNumber: element.phoneNumber,
        keyPosition: element.keyPosition,
        lock: element.lock,
      };
      switch (element.status.toLowerCase()) {
        case 'booking':
          dataNew.push(data);
          break;
        case 'checkedin':
          dataCheckedin.push(data);
          break;
        case 'done':
          dataDone.push(data);
          break;
        default:
          break;
      }
    });
    return ({
      dataNew, dataCheckedin, dataDone,
    });
  }

  toggleTab = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  onClickCreate = () => {
    this.props.history.push('/admin/createbooking');
  }

  render = () => {
    const {
      activeTab,
      dataNew,
      dataCheckedin,
      dataDone,
    } = this.state;

    let tabNewBookings = <div />;
    let tabCheckedinBookings = <div />;
    let tabDoneBookings = <div />;

    tabNewBookings = (
      <New
        data={dataNew}
      />
    );

    tabCheckedinBookings = (
      <CheckedIn
        data={dataCheckedin}
        isDataCheckins
      />
    );

    tabDoneBookings = (
      <Done
        data={dataDone}
        isDataCheckouts
      />
    );

    return (
      <div className="portal">
        <main className="main">
          <Container fluid>
            <Row>
              <Col xs="6">
                <h2 className="mt-3 mb-3 text__header">
                  BOOKINGS MANAGEMENT
                </h2>
              </Col>
              <Col xs="6" className="align-self-center text-right">
                <Button
                  className="btn"
                  onClick={this.onClickCreate}
                >
                  Add Booking
                </Button>
              </Col>
            </Row>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { this.toggleTab('1'); }}
                >
                  New
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { this.toggleTab('2'); }}
                >
                  Checked-in
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { this.toggleTab('4'); }}
                >
                  Done
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col xs="12">
                    {tabNewBookings}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col xs="12">
                    {tabCheckedinBookings}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col xs="12">
                    {tabDoneBookings}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Container>
        </main>
      </div>
    );
  }
}

PortalBookings.defaultProps = {
  location: {
    state: {},
  },
};

PortalBookings.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    bookings: state.bookings,
  };
}

export default withRouter(connect(mapStateToProps, null)(PortalBookings));
