import initialState from './initialState';


function processGetAllApi(state = initialState, action) {
  const { data } = action;

  Object.assign(state, data);

  const returnValue = JSON.parse(JSON.stringify(state));

  return returnValue;
}

export default processGetAllApi;
