import { put, take, fork } from 'redux-saga/effects';
import AppFlowActions from '../../constants';


function* setSearchFlow() {
  const INFINITE = true;
  while (INFINITE) {
    const request = yield take(AppFlowActions.SET_SEARCH_REQUEST);
    const { data } = request;

    if (data) {
      yield put({ type: AppFlowActions.SET_SEARCH_COMPLETE, data });
    }
  }
}

export default function* searchFlow() {
  yield fork(setSearchFlow);
}
