import {
  put, call, take, fork,
} from 'redux-saga/effects';

import auth from '../Helpers/auth';
import AppFlowActions from '../../constants';

import { fetchLogin } from '../Helpers/fetch';

function fetch(data) {
  const { userName, password } = data;
  try {
    const body = {
      userName,
      password,
    };
    return fetchLogin(body);
  } catch (error) {
    return null;
  }
}
/**
 * Log in saga
 */

export function* loginRequest() {
  const INFINITE = true;
  while (INFINITE) {
    const request = yield take(AppFlowActions.LOGIN_REQUEST);
    // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: true });

    const { data } = request;

    const result = yield call(fetch, { ...data });

    if (result) {
      if (result.token) {
        auth.login(result);
      }

      yield put({ type: AppFlowActions.LOGIN_COMPLETE, data: result });
      if (result.token) {
        yield put({ type: AppFlowActions.GET_ALL_DATA_REQUEST });
      }

      // yield put({ type: AppFlowActions.LOADING_COMPLETE, isLoading: false });
    }
  }
}


export default function* loginFlow() {
  yield fork(loginRequest);
}
