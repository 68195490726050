import initialState from './initialState';
import AppFlowActions from '../../constants';

const loading = (state = initialState.loading, action) => {
  switch (action.type) {
    case AppFlowActions.LOADING_COMPLETE:
      return { ...action };
    default:
      return state;
  }
};

export default loading;
