import React, { Component } from 'react';
import { connect } from 'react-redux';

class Loading extends Component {
  state = { loading: false }

  static getDerivedStateFromProps = (nextProps) => {
    const { loading } = nextProps;
    const { isLoading } = loading;

    return { loading: isLoading };
  }

  render = () => {
    const { loading } = this.state;

    if (loading) {
      document.body.classList.add('no-scroll');

      return (
        <div className="sk-circle">
          <div className="sk-circle1 sk-child" />
          <div className="sk-circle2 sk-child" />
          <div className="sk-circle3 sk-child" />
          <div className="sk-circle4 sk-child" />
          <div className="sk-circle5 sk-child" />
          <div className="sk-circle6 sk-child" />
          <div className="sk-circle7 sk-child" />
          <div className="sk-circle8 sk-child" />
          <div className="sk-circle9 sk-child" />
          <div className="sk-circle10 sk-child" />
          <div className="sk-circle11 sk-child" />
          <div className="sk-circle12 sk-child" />
        </div>
      );
    }

    document.body.classList.remove('no-scroll');

    return null;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading,
    login: state.login,
  };
}

export default connect(mapStateToProps)(Loading);
