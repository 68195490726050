import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import arrowLeftCircle from '../../image/arrow-left-circle.svg';

import CreateBookingUserInfo from '../../components/CreateBookingUserInfo';

import loadingPage from '../../Redux/Actions/loading';
import getAllRequest from '../../Redux/Actions/getall';
import { getLockAvailableAction } from '../../Redux/Actions/locks';

import { fetchCreateBooking } from '../../Redux/Helpers/fetch';

class CreateBooking extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  // handleChangeTime = (timeType, date) => {
  //   if (timeType === 'checkIn') {
  //     if (date) {
  //       this.setState({ timeBookCheckIn: date });
  //     } else {
  //       this.setState({
  //         timeBookCheckIn: null,
  //         maxDate: null,
  //         minDate: new Date(moment().startOf('d')),
  //       });
  //     }
  //   } else if (date) {
  //     this.setState({ timeBookCheckOut: date });
  //   } else {
  //     this.setState({ timeBookCheckOut: null, maxDate: null });
  //   }
  // }

  handleFinishBooking = (dataUser) => {
    try {
      this.props.loadingPage(true);
      return fetchCreateBooking(dataUser).then(async (result) => {
        if (result.error) {
          this.props.loadingPage(false);
          toastr.error(result.message, 'Error');
        } else {
          this.props.getAllRequest();

          this.props.loadingPage(false);
          toastr.success('Book success', 'Success');
          this.props.history.push('./bookings');
        }
      });
    } catch (error) {
      console.log('handleFinishBooking', error);
      return false;
    }
  }

  render = () => (
    <div className="create-booking">
      <main className="main">
        <Container fluid>
          <Row className="booking-name">
            <Link to="/admin/bookings">
              <img src={arrowLeftCircle} alt="arrow-left-circle" className="btn--back" />
            </Link>
            <div className="create-booking__header">
              <h2 className="text__header">
                  NEW BOOKING
              </h2>
            </div>
          </Row>
          <CreateBookingUserInfo
            handleFinishBooking={this.handleFinishBooking}
            locks={this.props.locksAvailable}
            machines={this.props.machines}
            onGetLockAvailable={this.props.onGetLockAvailable}
          />
        </Container>
      </main>
    </div>
  )
}

CreateBooking.propTypes = {
  loadingPage: PropTypes.func.isRequired,
  getAllRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onGetLockAvailable: PropTypes.func.isRequired,
  locksAvailable: PropTypes.objectOf(PropTypes.array).isRequired,
  machines: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ locks, machines }) => ({
  locksAvailable: locks.locksAvailable,
  machines,
});

const mapDispatchToProps = {
  loadingPage,
  getAllRequest,
  onGetLockAvailable: getLockAvailableAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBooking);
