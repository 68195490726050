import AppFlowActions from '../../constants';

/**
 *
 * @param {*}
 */
export const getAllLocksOfMachineAction = () => ({
  type: AppFlowActions.GET_ALL_LOCKS_OF_MACHINE_REQUEST,
});

/**
 *
 * @param {string} serialMachine
 */
export const getPositionUnavailableAction = serialMachine => ({
  type: AppFlowActions.GET_POSTION_UNAVAILABLE_REQUEST,
  serialMachine,
});

/**
 *
 * @param {object} lock
 * @param {function} history
 */
export const createLockAction = (lock, history) => ({
  type: AppFlowActions.CREATE_LOCK_REQUEST,
  lock,
  history,
});

/**
 *
 * @param {string} idLock
 */
export const deleteLockAction = idLock => ({
  type: AppFlowActions.DELETE_LOCK_REQUEST,
  idLock,
});

/**
 *
 * @param {string} serialMachine
 */
export const getLockAvailableAction = serialMachine => ({
  type: AppFlowActions.GET_LOCK_AVAILABLE_REQUEST,
  serialMachine,
});

/**
 *
 * @param {string} lockId
 * @param {object} lock
 */
export const updateLockAction = (lockId, lock) => ({
  type: AppFlowActions.UPDATE_LOCK_REQUEST,
  lockId,
  lock,
});

/**
 *
 * @param {string} serialMachine
 */
export const getLocksOccupiedAction = serialMachine => ({
  type: AppFlowActions.GET_LOCKS_OCCUPIED_REQUEST,
  serialMachine,
});
