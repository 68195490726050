import { isEmpty, isArray } from 'lodash';

export const memorizeMachines = (data) => {
  if (isEmpty(data) || !isArray(data)) return {};

  return data.reduce((result, item) => {
    result[item.serialMachine] = item;
    return result;
  }, {});
};

export const memorizeLocks = (data) => {
  if (isEmpty(data) || !isArray(data)) return {
    locks: {},
    lockIds: [],
  };

  return data.reduce((result, item) => {
    result.locks[item.id] = item;
    result.lockIds.push(item.id);
    return result;
  }, {
    locks: {},
    lockIds: [],
  });
}
